import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class FormreportsService {
  // getAllRole(arg0: (response: any) => void) {
  //   throw new Error('Method not implemented.');
  // }

  formData = { bookTypeId: '' };


  constructor(public http: HttpClient, public service: ApiRootService) { }

  getAllCat(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFormsCategory').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getAllFormlistByCatId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormtDetailsByCategoryId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getAllFormlistAnsByChecklistId(formlist, vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAnswerDetailsByFormIdAndDate/' + vesselId + '/' + formlist.formlistId + '/' + formlist.actionDate).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormAnswerDetailsByFormIdAndDateWithTime(forms, vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAnswerDetailsByFormIdAndDateWithTime/' + vesselId + '/' + forms.id + '/' + forms.actionDate + '/' + forms.actionTime).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormAnswerDetailsByFormIdAndDateWithTimev1(forms, vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/v1/getFormAnswerDetailsByFormIdAndDateWithTime/' + vesselId + '/' + forms.id + '/' + forms.actionDate + '/' + forms.actionTime).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getAllNotes(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormNotesByFormId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormAnswerDetailsByFormIdAndDateGroupByActionDate(id, vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAnswerDetailsByFormIdAndDateGroupByActionDate/' + vesselId + '/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormlistDetailsByFormlistId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormsDetailsByFormsId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }


  getFormlistApprovalDetails(forms, vesselId, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAnswerDetailsApprovalByFormIdAndDateWithTime/' + vesselId + '/' + forms.id + '/' + forms.actionDate + '/' + forms.actionTime).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  saveFormlistApprovalDetails(approvalDetail, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveFormAnswerDetailsApproval', approvalDetail).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  saveFormAnswerDetails(formValues, callback) {
    return this.http.post(this.service.getBaseUrl() + '/saveFormAnswerDetails', formValues).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  // http://3.141.94.216:8089/campbellservice/saveFormAnswerDetailsList

  saveFormAnswerDetailsList(formData, callback) {
    console.log('FormValues', formData);
    return this.http.post(this.service.getBaseUrl() + '/saveFormAnswerDetailsList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  saveNewFormAnswerDetailsList(formData, callback) {
    console.log('FormValues', formData);
    return this.http.post(this.service.getBaseUrl() + '/saveFormAnswerDetailsList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  // http://3.141.94.216:9002/campbellservice/getFormsQuestionDetailsByFormsId/186

  getFormsQuestionDetailsByFormsId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormsQuestionDetailsByFormsId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }


  getCrewByCrewIdList(userIds, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCrewByCrewIdList/' + userIds).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormAdditionalInfoByFormId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAdditionalInfoByFormId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  getFormAnsAdditionalInfoByFormIdWithDateTime(forms, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormAnsAdditionalInfoByFormIdWithDateTime/' + forms.id + '/' + forms.actionDate + '/' + forms.actionTime).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  completeSaveFormAnswer(formData, callback) {
    console.log('check-FormValues', formData);
    return this.http.post(this.service.getBaseUrl() + '/completeAndSaveFormAnswerDetailsList', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

}




