import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SailingModesChangeLogService } from '../sailing-modes-change-log.service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { DatePipe } from '@angular/common';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-sailing-modes-change-log',
  templateUrl: './sailing-modes-change-log.component.html',
  styleUrls: ['./sailing-modes-change-log.component.css']
})
export class SailingModesChangeLogComponent implements OnInit {

  //displayedColumns: string[] = ['id', 'mode', 'changeDateTime', 'changedByUser', 'edit'];
  displayedColumns: string[] = ['mode', 'changeDateTime', 'changedByUser', 'edit'];
  dataSource;
  dateModel: Date = new Date();

  stringDateModel: string = new Date().toString();

  myForm: FormGroup;
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  fieldTypeId: any;
  product: any;
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  modesData = [];
  crewData;


  constructor(public datePipe: DatePipe, public fb: FormBuilder, public sailingModesChangeLogService: SailingModesChangeLogService, public http: HttpClient) {
    this.resetForm();
  }

  ngOnInit() {

    console.log("==sailingModesChangeLogService.formData==",this.sailingModesChangeLogService.formData);
    this.sailingModesChangeLogService.getModesData((response) => {

      response.data.forEach((item) => {
        let i = this.modesData.findIndex(x => x.sailingMode == item.sailingMode);
        if (i <= -1) {
          this.modesData.push(item);
        }
      });
    });

    this.sailingModesChangeLogService.getCrewrData((response) => {
      this.crewData = response.data;
      this.crewData= _.sortBy( this.crewData, 'firstName' );

    });
    this.sailingModesChangeLogService.getData((response) => {
      console.log("==sailingModesChangeLogService==",response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })



    this.myForm = this.fb.group({
      'mode': new FormControl(null, [Validators.required]),
      'changeDateTime': new FormControl(null, { validators: [Validators.required, DateTimeValidator] }),
      'changedByUser': new FormControl(null, [Validators.required])
    }, { updateOn: 'change' })

  }



  submitHandler() {
    console.log(this.myForm.value);
     if (this.myForm.valid) {
       console.log(this.myForm.value);
       if (this.sailingModesChangeLogService.formData.id) {
         this.sailingModesChangeLogService.formData.changeDateTime = this.datePipe.transform(this.sailingModesChangeLogService.formData.changeDateTime, 'dd-MM-yyyy hh:mm:ss');
         this.sailingModesChangeLogService.putData(this.sailingModesChangeLogService.formData.id, this.sailingModesChangeLogService.formData, (data) => {
           console.log(data);
           if (data.responsecode === 200 || data.responsecode === '200') {
             this.sailingModesChangeLogService.getData((response) => {
               console.log(response);
               this.dataSource = new MatTableDataSource(response.data);
               this.dataSource.paginator = this.paginator;
               this.dataSource.sort = this.sort;
               this.successbox.show();
               this.resetForm();
               this.myForm.reset();
             })
           } else {
             this.errorbox.text = data.message;
             this.errorbox.show();
           }
         })
       }
       else {
         this.sailingModesChangeLogService.formData.changeDateTime = this.datePipe.transform(this.sailingModesChangeLogService.formData.changeDateTime, 'dd-MM-yyyy hh:mm:ss');
 
         this.sailingModesChangeLogService.postData(this.sailingModesChangeLogService.formData, (response) => {
           console.log(response);
           if (response.responsecode === 200 || response.responsecode === '200') {
             this.sailingModesChangeLogService.getData((response) => {
               console.log(response);
               this.dataSource = new MatTableDataSource(response.data);
               this.dataSource.paginator = this.paginator;
               this.dataSource.sort = this.sort;
               this.successbox.show();
               this.resetForm();
               this.myForm.reset();
             })
           } else {
             this.errorbox.text = response.message;
             this.errorbox.show();
           }
         })
       }
 
     }
   }
 





  // getProductDetails(id) {
  //   this.fieldTypeService.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    if (action == 'edit') {
      this.sailingModesChangeLogService.formData.id = data.id;
      this.sailingModesChangeLogService.formData.mode = data.mode;
      this.sailingModesChangeLogService.formData.changeDateTime = new Date(data.changeDateTime).toString();
      this.sailingModesChangeLogService.formData.changedByUser = data.changedByUser;
      const dateNumber = data.changeDateTime.split(' ')[0];
      const timeNumber = data.changeDateTime.split(' ')[1];

      const time = timeNumber.split(':');
      let hours = +time[0];
      let min = +time[1];
      console.log(hours);

      const month = dateNumber.split('-');
      const newDate = new Date(+month[2], +month[1] - 1, +month[0], hours, min);
      console.log(newDate);
      this.sailingModesChangeLogService.formData.changeDateTime = newDate.toString();
      this.stringDateModel = newDate.toString();
    } else {
      this.sailingModesChangeLogService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.sailingModesChangeLogService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    // this.myForm.reset();
    this.sailingModesChangeLogService.formData.id = "";
    this.sailingModesChangeLogService.formData.mode = "";
    this.sailingModesChangeLogService.formData.changeDateTime = "";
    this.sailingModesChangeLogService.formData.changedByUser = "";
  }


}

export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
    isValid: {
      valid: false
    }
  };
};

