

import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppCommonService } from 'src/app/app.common.service';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRootService } from 'src/app/apiroot.service';
import { ReportsService } from '../reports.service';
// import { AnyTxtRecord } from 'dns';


@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  htmlContent: string;
  private history: string[] = [];
  isPdfLoading: boolean = false;
  pdfType: string;
  pdfTitleName: string;
  downloadPdfUrl: any;
  constructor(private route: ActivatedRoute, public service: ApiRootService, private _sanitizer: DomSanitizer, public commonService: AppCommonService, private location: Location, private rs: ReportsService, private router: Router, public dialogRef: MatDialogRef<DownloadReportComponent>,
    public http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    console.log("check-data", this.data);
    if (this.data && this.data.htmlContent) {
      this.htmlContent = this.transform(this.data.htmlContent);
      this.pdfTitleName = this.data.name;
      this.pdfType = this.data.pdfType;
      this.downloadPdfUrl = this.data.downloadPdfUrl;
    }
  }


  downloadPDF(pdfTitleName: any) {

    console.log(this.pdfType, this.pdfTitleName + 'pdfType');

    var isPdfLoading = document.getElementById('isPdfLoading');
    isPdfLoading.style.display = 'inline-block';
    console.log("isPdf Flag ::", this.isPdfLoading);
    var node = document.getElementById("print-section");
    var htmlContent = node.innerHTML;
    console.log("htmlContent :::", htmlContent);
    if (this.pdfType === 'log') {
      this.commonService.downloadPdfCommonFn(pdfTitleName, isPdfLoading);

    } else {
      console.log("Before response ::");
      this.convertHtmlToPdf(htmlContent).subscribe((res: Blob) => {
        console.log("response ::", res);
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        this.downloadPdf(res, this.pdfTitleName + '.pdf',);
        window.URL.revokeObjectURL(url);
        isPdfLoading.style.display = 'none';
      }, (error) => {
        console.error('PDF generation failed:', error);
      });
    }
  }

  downloadPdf(data: Blob, filename: string) {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  convertHtmlToPdf(htmlContent: string, options: any = {}): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { html: htmlContent, ...options };
    return this.http.post(this.downloadPdfUrl, body, { headers, responseType: 'blob' });
    // return this.http.post(`${this.service.DOWNLOAD_PDF}`, body, { headers, responseType: 'blob' });
  }

  transform(v: any): any {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  back(): void {
    this.location.back();
  }

}
