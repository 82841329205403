import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { DialogboxComponent } from "../dialogbox/dialogbox.component";
import { CrewService } from "./crew.service";
import { HttpClient } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { RolesService } from "../roles/roles.service";
import { CompanyService } from "../company/company.service";
import { DialogboxService } from "../dialogbox/dialogbox.service";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { SessionStorageService } from "angular-web-storage";
import { DomSanitizer } from "@angular/platform-browser";
import { ReportsService } from "../reports/reports.service";

export interface Nation {
  value: string;
  viewValue: string;
}

export interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-crew",
  templateUrl: "./crew.component.html",
  styleUrls: ["./crew.component.scss"],
})
export class CrewComponent implements OnInit {
  //displayedColumns: string[] = ['id','crewNumber', 'crewName', 'email', 'roleId', 'nationality', 'gender','signatureImage', 'active', 'edit'];
  displayedColumns: string[] = [
    "crewName",
    "company",
    "email",
    "roleId",
    "nationality",
    "gender",
    "signatureImage",
    "active",
    "edit",
  ];
  dataSource;

  isLoading = true;
  loading = false;
  myForm: FormGroup;
  maxDate: string;
  afs: any;
  success: boolean;
  // loading: boolean;
  data: any = [];
  product: any;
  public formData;
  rolename = [];
  companyData: any = [];
  userCompanyId: any = [];
  loginRoleName: any = [];
  vesselAssignmentData: any = [];
  vesselAssignmentDataByCrewId: any = [];
  disabledtime: any = false;
  formSubmitted: any = false;
  // isEditMode: boolean = false;
  signatureUrl: string;
  hide = true;
  serverName: any;
  serverValue: any;

  nations: Nation[] = [
    { value: "AA-Aruba", viewValue: "AA-Aruba" },
    { value: "AC-Antigua and Barbuda", viewValue: "AC-Antigua and Barbuda" },
    { value: "AE-United Arab Emirates", viewValue: "AE-United Arab Emirates" },
    { value: "AF-Afghanistan", viewValue: "AF-Afghanistan" },
    { value: "AG-Algeria", viewValue: "AG-Algeria" },

    { value: "AJ-Azerbaijan", viewValue: "AJ-Azerbaijan" },
    { value: "AL-Albania", viewValue: "AL-Albania" },
    { value: "AM-Armenia", viewValue: "AM-Armenia" },
    { value: "AN-Andorra", viewValue: "AN-Andorra" },
    { value: "AO-Angola", viewValue: "AO-Angola" },

    { value: "AQ-American Samoa", viewValue: "AQ-American Samoa" },
    { value: "AR-Argentina", viewValue: "AR-Argentina" },
    { value: "AS-Australia", viewValue: "AS-Australia" },
    {
      value: "AT-Ashmore and Cartier Islands",
      viewValue: "AT-Ashmore and Cartier Islands",
    },
    { value: "AU-Austria", viewValue: "AU-Austria" },

    { value: "AV-Anguilla", viewValue: "AV-Anguilla" },
    { value: "AX-Akrotiri", viewValue: "AX-Akrotiri" },
    { value: "AY-Antarctica", viewValue: "AY-Antarctica" },
    { value: "BA-Bahrain", viewValue: "BA-Bahrain" },
    { value: "BB-Barbados", viewValue: "BB-Barbados" },

    { value: "BC-Botswana", viewValue: "BC-Botswana" },
    { value: "BD-Bermuda", viewValue: "BD-Bermuda" },
    { value: "BE-Belgium", viewValue: "BE-Belgium" },
    { value: "AF-Afghanistan", viewValue: "AF-Afghanistan" },
    { value: "BF-Bahamas, The", viewValue: "BF-Bahamas, The" },

    { value: "BG-Bangladesh", viewValue: "BG-Bangladesh" },
    { value: "BH-Belize", viewValue: "BH-Belize" },
    {
      value: "BK-Bosnia and Herzegovina",
      viewValue: "BK-Bosnia and Herzegovina",
    },
    { value: "BL-Bolivia", viewValue: "BL-Bolivia" },
    { value: "ABM-Burma", viewValue: "BM-Burma" },

    { value: "BN-Benin", viewValue: "BN-Benin" },
    { value: "BO-Belarus", viewValue: "BO-Belarus" },
    { value: "BP-Solomon Islands", viewValue: "BP-Solomon Islands" },
    { value: "BQ-Navassa Island", viewValue: "BQ-Navassa Island" },
    { value: "BR-Brazil", viewValue: "BR-Brazil" },
    { value: "BS-Bahamas", viewValue: "BS-Bahamas" },

    {
      value: "BS-French Southern and Antarctic Lands Bassas da India",
      viewValue: "BS-French Southern and Antarctic Lands Bassas da India",
    },
    { value: "BT-Bhutan", viewValue: "BT-Bhutan" },
    { value: "BU-Bulgaria", viewValue: "BU-Bulgaria" },
    { value: "BV-Bouvet Island", viewValue: "BV-Bouvet Island" },
    { value: "BX-Brunei", viewValue: "BX-Brunei" },

    { value: "BY-Burundi", viewValue: "BY-Burundi" },
    { value: "CA-Canada", viewValue: "CA-Canada" },
    { value: "CB-Cambodia", viewValue: "CB-Cambodia" },
    { value: "CD-Chad", viewValue: "CD-Chad" },
    { value: "CE-Sri Lanka", viewValue: "CE-Sri Lanka" },

    { value: "CF-Congo (Brazzaville)", viewValue: "CF-Congo (Brazzaville)" },
    { value: "CG-Congo (Kinshasa)", viewValue: "CG-Congo (Kinshasa)" },
    { value: "CH-China", viewValue: "CH-China" },
    { value: "CI-Chile", viewValue: "CI-Chile" },
    { value: "CJ-Cayman Islands", viewValue: "CJ-Cayman Islands" },

    {
      value: "CK-Cocos (Keeling) Islands",
      viewValue: "CK-Cocos (Keeling) Islands",
    },
    { value: "CM-Cameroon", viewValue: "CM-Cameroon" },
    { value: "CN-Comoros", viewValue: "CN-Comoros" },
    { value: "CO-Colombia", viewValue: "CO-Colombia" },
    {
      value: "CQ-Northern Mariana Islands",
      viewValue: "CQ-Northern Mariana Islands",
    },

    { value: "CR-Coral Sea Islands", viewValue: "CR-Coral Sea Islands" },
    { value: "CS-Costa Rica", viewValue: "CS-Costa Rica" },
    {
      value: "CT-Central African Republic",
      viewValue: "CT-Central African Republic",
    },
    { value: "CU-Cuba", viewValue: "CU-Cuba" },
    { value: "CV-Cape Verde", viewValue: "CV-Cape Verde" },

    { value: "CW-Cook Islands", viewValue: "CW-Cook Islands" },
    { value: "CY-Cyprus", viewValue: "CY-Cyprus" },
    { value: "DA/DK-Denmark", viewValue: "DA/DK-Denmark" },
    { value: "DJ-Djibouti", viewValue: "DJ-Djibouti" },
    { value: "DO-Dominica", viewValue: "DO-Dominica" },

    { value: "DQ-Jarvis Island", viewValue: "DQ-Jarvis Island" },
    { value: "DR-Dominican Republic", viewValue: "DR-Dominican Republic" },
    { value: "EG-Egypt", viewValue: "EG-Egypt" },
    { value: "EC-Ecuador", viewValue: "EC-Ecuador" },
    { value: "EI-Ireland", viewValue: "EI-Ireland" },

    { value: "EK-Equatorial Guinea", viewValue: "EK-Equatorial Guinea" },
    { value: "EN-Estonia", viewValue: "EN-Estonia" },
    { value: "ER-Eritrea", viewValue: "ER-Eritrea" },
    { value: "ES-El Salvador", viewValue: "ES-El Salvador" },
    { value: "ET-Ethiopia", viewValue: "ET-Ethiopia" },

    {
      value: "EU-French Southern and Antarctic Lands Europa Island",
      viewValue: "EU-French Southern and Antarctic Lands Europa Island",
    },
    { value: "EZ-Czech Republic", viewValue: "EZ-Czech Republic" },
    { value: "FG-French Guiana", viewValue: "FG-French Guiana" },
    { value: "FI-Finland", viewValue: "FI-Finland" },
    { value: "FJ-Fiji", viewValue: "FJ-Fiji" },

    {
      value: "FK-Falkland Islands (Islas Malvinas)",
      viewValue: "FK-Falkland Islands (Islas Malvinas)",
    },
    {
      value: "FM-Micronesia, Federated States of",
      viewValue: "FM-Micronesia, Federated States of",
    },
    { value: "FO-Faroe Islands", viewValue: "FO-Faroe Islands" },
    { value: "FP-French Polynesia", viewValue: "FP-French Polynesia" },
    { value: "FQ-Baker Island", viewValue: "FQ-Baker Island" },

    { value: "FR-France", viewValue: "FR-France" },
    {
      value: "FS-French Southern and Antarctic Lands",
      viewValue: "FS-French Southern and Antarctic Lands",
    },
    { value: "GA-Gambia, The", viewValue: "GA-Gambia, The" },
    { value: "GB-Gabon", viewValue: "GB-Gabon" },
    { value: "GG-Georgia", viewValue: "GG-Georgia" },

    { value: "GH-Ghana", viewValue: "GH-Ghana" },
    { value: "GI-Gibraltar", viewValue: "GI-Gibraltar" },
    { value: "GJ-Grenada", viewValue: "GJ-Grenada" },
    { value: "GK-Guernsey", viewValue: "GK-Guernsey" },
    { value: "GL-Greenland", viewValue: "GL-Greenland" },

    { value: "GM-Germany", viewValue: "GM-Germany" },
    {
      value: "GO-French Southern and Antarctic Lands Glorioso Islands",
      viewValue: "GO-French Southern and Antarctic Lands Glorioso Islands",
    },
    { value: "GP-Guadeloupe", viewValue: "GP-Guadeloupe" },
    { value: "GQ-Guam", viewValue: "GQ-Guam" },
    { value: "GR-Greece", viewValue: "GR-Greece" },

    { value: "GT-Guatemala", viewValue: "GT-Guatemala" },
    { value: "GV-Guinea", viewValue: "GV-Guinea" },
    { value: "GY-Guyana", viewValue: "GY-Guyana" },
    { value: "GZ-Gaza Strip", viewValue: "GZ-Gaza Strip" },
    { value: "HA-Haiti", viewValue: "HA-Haiti" },

    { value: "HK-Hong Kong", viewValue: "HK-Hong Kong" },
    {
      value: "HM-Heard Island and McDonald Islands",
      viewValue: "HM-Heard Island and McDonald Islands",
    },
    { value: "HO-Honduras", viewValue: "HO-Honduras" },
    { value: "HQ-Howland Island", viewValue: "HQ-Howland Island" },
    { value: "HR-Croatia", viewValue: "HR-Croatia" },

    { value: "HU-Hungary", viewValue: "HU-Hungary" },
    { value: "IC-Iceland", viewValue: "IC-Iceland" },
    { value: "ID-Indonesia", viewValue: "ID-Indonesia" },
    { value: "IM-Isle of Man", viewValue: "IM-Isle of Man" },
    { value: "IN-India", viewValue: "IN-India" },

    {
      value: "IO-British Indian Ocean Territory",
      viewValue: "IO-British Indian Ocean Territory",
    },
    { value: "IP-Clipperton Island", viewValue: "IP-Clipperton Island" },
    { value: "IR-Iran", viewValue: "IR-Iran" },
    { value: "IS-Israe", viewValue: "IS-Israel" },
    { value: "IT-Italy", viewValue: "IT-Italy" },

    { value: "IV-Côte d'Ivoire", viewValue: "IV-Côte d'Ivoire" },
    { value: "IZ-Iraq", viewValue: "IZ-Iraq" },
    { value: "JA-Japan", viewValue: "JA-Japan" },
    { value: "JE-Jersey", viewValue: "JE-Jersey" },
    { value: "JM-Jamaica", viewValue: "JM-Jamaica" },

    { value: "JN-Jan Mayen", viewValue: "JN-Jan Mayen" },
    { value: "JO-Jordan", viewValue: "JO-Jordan" },
    { value: "JQ-Johnston Atoll", viewValue: "JQ-Johnston Atoll" },
    {
      value: "JU-French Southern and Antarctic Lands Juan de Nova Island",
      viewValue: "JU-French Southern and Antarctic Lands Juan de Nova Island",
    },
    { value: "KE-Kenya", viewValue: "KE-Kenya" },

    { value: "HU-Hungary", viewValue: "HU-Hungary" },
    { value: "KG-Kyrgyzstan", viewValue: "KG-Kyrgyzstan" },
    { value: "KN-Korea, North", viewValue: "KN-Korea, North" },
    { value: "KQ-Kingman Reef", viewValue: "KQ-Kingman Reef" },
    { value: "KR-Kiribati", viewValue: "KR-Kiribati" },

    { value: "KS-Korea, South", viewValue: "KS-Korea, South" },
    { value: "KT-Christmas Island", viewValue: "KT-Christmas Island" },
    { value: "KU-Kuwait", viewValue: "KU-Kuwait" },
    { value: "KV-Kosovo", viewValue: "KV-Kosovo" },
    { value: "KZ-Kazakhstan", viewValue: "KZ-Kazakhstan" },

    { value: "LA-Laos", viewValue: "LA-Laos" },
    { value: "LE-Lebanon", viewValue: "LE-Lebanon" },
    { value: "LG-Latvia", viewValue: "LG-Latvia" },
    { value: "LH-Lithuania", viewValue: "LH-Lithuania" },
    { value: "LI-Liberia", viewValue: "LI-Liberia" },

    { value: "LO-Slovakia", viewValue: "LO-Slovakia" },
    { value: "LQ-Palmyra Atoll", viewValue: "LQ-Palmyra Atoll" },
    { value: "LS-Liechtenstein", viewValue: "LS-Liechtenstein" },
    { value: "LT-Lesotho", viewValue: "LT-Lesotho" },
    { value: "LU-Luxembourg", viewValue: "LU-Luxembourg" },

    { value: "LA-Laos", viewValue: "LA-Laos" },
    { value: "LE-Lebanon", viewValue: "LE-Lebanon" },
    { value: "LG-Latvia", viewValue: "LG-Latvia" },
    { value: "LH-Lithuania", viewValue: "LH-Lithuania" },
    { value: "LY-Libya", viewValue: "LY-Libya" },

    { value: "MA-Madagascar", viewValue: "MA-Madagascar" },
    { value: "MB-Martinique", viewValue: "MB-Martinique" },
    { value: "MC-Macau", viewValue: "MC-Macau" },
    { value: "MD-Moldova", viewValue: "MD-Moldova" },
    { value: "MF-Mayotte", viewValue: "MF-Mayotte" },

    { value: "MG-Mongolia", viewValue: "MG-Mongolia" },
    { value: "MH-Montserrat", viewValue: "MH-Montserrat" },
    { value: "MI-Malawi", viewValue: "MI-Malawi" },
    { value: "MJ-Montenegro", viewValue: "MJ-Montenegro" },
    { value: "MK-North Macedonia", viewValue: "MK-North Macedonia" },

    { value: "ML-Mali", viewValue: "ML-Mali" },
    { value: "MM-Myanmar", viewValue: "MM-Myanmar" },
    { value: "MN-Monaco", viewValue: "MN-Monaco" },
    { value: "MO-Morocco", viewValue: "MO-Morocco" },
    { value: "MP-Mauritius", viewValue: "MP-Mauritius" },
    { value: "MQ-Midway Islands", viewValue: "MQ-Midway Islands" },

    { value: "MR-Mauritania", viewValue: "MR-Mauritania" },
    { value: "MT-Malta", viewValue: "MT-Malta" },
    { value: "MU-Oman", viewValue: "MU-Oman" },
    { value: "MV-Maldives", viewValue: "MV-Maldives" },
    { value: "MX-Mexico", viewValue: "MX-Mexico" },

    { value: "MR-Mauritania", viewValue: "MR-Mauritania" },
    { value: "MT-Malta", viewValue: "MT-Malta" },
    { value: "MU-Oman", viewValue: "MU-Oman" },
    { value: "MV-Maldives", viewValue: "MV-Maldives" },
    { value: "MX-Mexico", viewValue: "MX-Mexico" },

    { value: "MY-Malaysia", viewValue: "MY-Malaysia" },
    { value: "MZ-Mozambique", viewValue: "MZ-Mozambique" },
    { value: "NC-New Caledonia", viewValue: "NC-New Caledonia" },
    { value: "NE-Niue", viewValue: "NE-Niue" },
    { value: "NF-Norfolk Island", viewValue: "NF-Norfolk Island" },

    { value: "NG-Niger", viewValue: "NG-Niger" },
    { value: "NH-Vanuatu", viewValue: "NH-Vanuatu" },
    { value: "NI-Nigeria", viewValue: "NI-Nigeria" },
    { value: "NL-Netherlands", viewValue: "NL-Netherlands" },
    { value: "SX-Sint Maarten", viewValue: "SX-Sint Maarten" },

    { value: "NO-Norway", viewValue: "NO-Norway" },
    { value: "NP-Nepal", viewValue: "NP-Nepal" },
    { value: "NR-Nauru", viewValue: "NR-Nauru" },
    { value: "NS-Suriname", viewValue: "NS-Suriname" },
    { value: "NU-Nicaragua", viewValue: "NU-Nicaragua" },

    { value: "NZ-New Zealand", viewValue: "NZ-New Zealand" },
    { value: "OD-South Sudan", viewValue: "OD-South Sudan" },
    { value: "PA-Paraguay", viewValue: "PA-Paraguay" },
    { value: "PE-Peru", viewValue: "PE-Peru" },
    { value: "PF-Paracel Islands", viewValue: "PF-Paracel Islands" },

    { value: "PG-Spratly Islands", viewValue: "PG-Spratly Islands" },
    {
      value: "PJ-Etorofu, Habomai, Kunashiri, and Shikotan Islands",
      viewValue: "PJ-Etorofu, Habomai, Kunashiri, and Shikotan Islands",
    },
    { value: "PK-Pakistan", viewValue: "PK-Pakistan" },
    { value: "PL-Poland", viewValue: "PL-Poland" },
    { value: "PM-Panama", viewValue: "PM-Panama" },

    { value: "PO-Portugal", viewValue: "PO-Portugal" },
    { value: "PP-Papua New Guinea", viewValue: "PP-Papua New Guinea" },
    { value: "PS-Palau", viewValue: "PS-Palau" },
    { value: "PU-Guinea-Bissau", viewValue: "PU-Guinea-Bissau" },
    { value: "QA-Qatar", viewValue: "QA-Qatar" },

    { value: "RE-Réunion", viewValue: "RE-Réunion" },
    { value: "RI-Serbia", viewValue: "RI-Serbia" },
    { value: "RM-Marshall Islands", viewValue: "RM-Marshall Islands" },
    { value: "RN-Saint Martin", viewValue: "RN-Saint Martin" },
    { value: "RO-Romania", viewValue: "RO-Romania" },

    { value: "RP-Philippines", viewValue: "RP-Philippines" },
    { value: "RQ-Puerto Rico", viewValue: "RQ-Puerto Rico" },
    { value: "RS-Russia", viewValue: "RS-Russia" },
    { value: "RW-Rwanda", viewValue: "RW-Rwanda" },
    { value: "SA-Saudi Arabia", viewValue: "SA-Saudi Arabia" },

    {
      value: "SB-Saint Pierre and Miquelon",
      viewValue: "SB-Saint Pierre and Miquelon",
    },
    {
      value: "SC-Saint Kitts and Nevis",
      viewValue: "SC-Saint Kitts and Nevis",
    },
    { value: "SE-Seychelles", viewValue: "SE-Seychelles" },
    { value: "SF-South Africa", viewValue: "SF-South Africa" },
    { value: "SG-Senegal", viewValue: "SG-Senegal" },

    {
      value: "SH-Saint Helena, Ascension, and Tristan da Cunha",
      viewValue: "SH-Saint Helena, Ascension, and Tristan da Cunha",
    },
    { value: "SI-Slovenia", viewValue: "SI-Slovenia" },
    { value: "SL-Sierra Leone", viewValue: "SL-Sierra Leone" },
    { value: "SM-San Marino", viewValue: "SM-San Marino" },
    { value: "SN-Singapore", viewValue: "SN-Singapore" },

    { value: "SO-Somalia", viewValue: "SO-Somalia" },
    { value: "SP-Spain", viewValue: "SP-Spain" },
    { value: "ST-Saint Lucia", viewValue: "ST-Saint Lucia" },
    { value: "SU-Sudan", viewValue: "SU-Sudan" },
    { value: "SV-Svalbard", viewValue: "SV-Svalbard" },

    { value: "SW-Sweden", viewValue: "SW-Sweden" },
    {
      value: "SX-South Georgia and South Sandwich Islands",
      viewValue: "SX-South Georgia and South Sandwich Islands",
    },
    { value: "SY-Syria", viewValue: "SY-Syria" },
    { value: "CH-Switzerland", viewValue: "CH-Switzerland" },
    { value: "TB-Saint Barthelemy", viewValue: "TB-Saint Barthelemy" },

    { value: "TD-Trinidad and Tobago", viewValue: "TD-Trinidad and Tobago" },
    {
      value: "TE-French Southern and Antarctic Lands Tromelin Island",
      viewValue: "TE-French Southern and Antarctic Lands Tromelin Island",
    },
    { value: "TH-Thailand", viewValue: "TH-Thailand" },
    { value: "TI-Tajikistan", viewValue: "TI-Tajikistan" },
    {
      value: "TK-Turks and Caicos Islands",
      viewValue: "TK-Turks and Caicos Islands",
    },

    { value: "TL-Tokelau", viewValue: "TL-Tokelau" },
    { value: "SP-Spain", viewValue: "SP-Spain" },
    { value: "ST-Saint Lucia", viewValue: "ST-Saint Lucia" },
    { value: "SU-Sudan", viewValue: "SU-Sudan" },
    { value: "SV-Svalbard", viewValue: "SV-Svalbard" },

    { value: "TN-Tonga", viewValue: "TN-Tonga" },
    { value: "TO-Togo", viewValue: "TO-Togo" },
    {
      value: "TP-Sao Tome and Principe",
      viewValue: "TP-Sao Tome and Principe",
    },
    { value: "TS-Tunisia", viewValue: "TS-Tunisia" },
    { value: "TT-Timor-Leste", viewValue: "TT-Timor-Leste" },

    { value: "TU-Turkey", viewValue: "TU-Turkey" },
    { value: "TV-Tuvalu", viewValue: "TV-Tuvalu" },
    { value: "TW-Taiwan", viewValue: "TW-Taiwan" },
    { value: "TX-Turkmenistan", viewValue: "TX-Turkmenistan" },
    { value: "TZ-Tanzania", viewValue: "TZ-Tanzania" },

    { value: "UP-Ukraine", viewValue: "UP-Ukraine" },
    { value: "UC-Curaçao", viewValue: "UC-Curaçao" },
    { value: "UG-Uganda", viewValue: "UG-Uganda" },
    { value: "UK-United Kingdom", viewValue: "UK-United Kingdom" },
    { value: "US-United States", viewValue: "US-United States" },

    { value: "UV-Burkina Faso", viewValue: "UV-Burkina Faso" },
    { value: "UY-Uruguay", viewValue: "UY-Uruguay" },
    { value: "UZ-Uzbekistan", viewValue: "UZ-Uzbekistan" },
    {
      value: "VC-Saint Vincent and the Grenadines",
      viewValue: "VC-Saint Vincent and the Grenadines",
    },
    { value: "VE-Venezuela", viewValue: "VE-Venezuela" },

    {
      value: "VI-British Virgin Islands",
      viewValue: "VI-British Virgin Islands",
    },
    { value: "VM-Vietnam", viewValue: "VM-Vietnam" },
    {
      value: "VQ-United States Virgin Islands",
      viewValue: "VQ-United States Virgin Islands",
    },
    { value: "TX-Turkmenistan", viewValue: "TX-Turkmenistan" },
    { value: "VT-Vatican City", viewValue: "VT-Vatican City" },

    { value: "WA-Namibia", viewValue: "WA-Namibia" },
    { value: "WE-West Bank", viewValue: "WE-West Bank" },
    { value: "WF-Wallis and Futuna", viewValue: "WF-Wallis and Futuna" },
    { value: "WQ-Wake Island", viewValue: "WQ-Wake Island" },
    { value: "WS-Samoa", viewValue: "WS-Samoa" },

    { value: "WZ-Eswatini", viewValue: "WZ-Eswatini" },
    { value: "YM-Yemen", viewValue: "YM-Yemen" },
    { value: "ZA-Zambia", viewValue: "ZA-Zambia" },
    { value: "ZI-Zimbabwe", viewValue: "ZI-Zimbabwe" },
  ];

  genders: Gender[] = [
    { value: "Male", viewValue: "Male" },
    { value: "Female", viewValue: "Female" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild("deleteSwal", { static: false }) public deleteSwal: SwalComponent;
  @ViewChild("successbox", { static: true }) public successbox: SwalComponent;
  @ViewChild("deletesuccessbox", { static: true }) public deletesuccessbox: SwalComponent;

  @ViewChild("errorbox", { static: true }) public errorbox: SwalComponent;
  @ViewChild('signaturePopup', { static: false }) signaturePopup: TemplateRef<any>;

  constructor(
    public session: SessionStorageService,
    private domSanitizer: DomSanitizer,
    public datePipe: DatePipe,
    public fb: FormBuilder,
    public crewService: CrewService,
    public companyService: CompanyService,
    public roleService: RolesService,
    public http: HttpClient,
    private dialog: MatDialog,
    private dialogboxService: DialogboxService,
    private cdr: ChangeDetectorRef,
    private rs: ReportsService
  ) {
    this.resetForm();
    this.maxDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit() {
    // this.isEditMode = false;
    this.userCompanyId = this.session.get("companyId");
    this.loginRoleName = this.session.get("rolename");

    // Fetch the shore/vessel server status and server name on init
    this.rs.getSettingDetail((res) => {
      console.log("check-ishore-value-from-ngOnInit", res.isShoreServer);
      if (res.isShoreServer == true || res.isShoreServer == 'true') {
        this.serverName = 'Shore Server';
        this.serverValue = res.isShoreServer;
      } else {
        this.serverName = 'Vessel Server';
        this.serverValue = res.isShoreServer;
      }
      console.log("check-serverName", this.serverName);
      console.log("check-serverValue", this.serverValue);
    });

    this.companyService.getData((response) => {
      console.log(response);
      this.companyData = response.data;
      console.log('Here is the all data', this.companyData)
    });

    of(this.formData)
      .pipe(delay(1000))
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.loading = data;
        },
        (error) => (this.isLoading = false)
      );
    // this.getProductDetails(this.route.snapshot.params['id']);

    this.getAllCrewData(this.userCompanyId);

    // this.roleService.getData((response) => {
    //   console.log(response);
    //   this.rolename = response.data;
    // });
    this.getAllRoleByCompanyId(this.userCompanyId);

    this.myForm = this.fb.group({
      companyId: new FormControl(this.userCompanyId, Validators.required),
      profileImage: new FormControl(null),

      firstName: new FormControl(null, [
        Validators.required,
        // Validators.maxLength(4),
        // Validators.minLength(2),
        Validators.pattern("^[a-zA-Z ]*$"),
      ]),

      middleName: new FormControl(null),
      lastName: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[a-zA-Z ]*$"),
      ]),
      email: new FormControl(null, [
        Validators.required,
        Validators.email,
        Validators.pattern(
          "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
        ),
      ]),
      // [a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}
      userName: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),

      // dateOfBirth: new FormControl(null, Validators.required),
      dateOfBirth: [null, [Validators.required, this.validateDOB.bind(this)]],
      roleId: new FormControl(null, Validators.required),
      nationality: new FormControl(null, Validators.required),
      gender: new FormControl(null, Validators.required),
      signatureImage: new FormControl(null),
      active: new FormControl(null),
    });

    this.getAllCrewVesselAssignment();
    // this.getCrewVesselAssignmentByCrewId();
  }

  getAllRoleByCompanyId(companyId) {
    this.loading = true;
    if (companyId) {
      this.roleService.getAllRolePermissionDetailsByCompanyId(companyId, (response) => {
        this.rolename = response.data;
        this.loading = false;
        console.log("==this.rolename 1==", this.rolename);
      });
    } else {
      this.roleService.getData((response) => {
        console.log(response);
        this.rolename = response.data;
        this.loading = false;
        console.log("==this.rolename 2==", this.rolename);
      });
    }
  }


  doFilter(value: string) {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  // refresh() {
  //   window.location.reload();
  // }

  togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  validateDOB(control: FormControl) {
    const selectedDate = new Date(control.value);
    const today = new Date();
    if (selectedDate > today) {
      return { futureDate: true };
    }
    return null;
  }

  getAllCrewData(companyId) {

    if (companyId) {
      this.crewService.getDataByCompanyId(companyId, (response) => {
        console.log(response);
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    } else {
      this.crewService.getData((response) => {
        console.log(response);
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }

  getCompanyNameById(id) {
    for (var comp of this.companyData) {
      if (comp.id == id) {
        //alert(comp.companyName)
        return comp.companyName;
        break;
      }
    }
  }

  triggerFileInput() {
    document.getElementById('real-input').click();
  }

  triggerSignatureInput() {
    document.getElementById('signature-input').click();
  }

  uploadProfileImage(event) {
    const fileInfo = document.querySelector(".file-info");
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20 ? name.substr(name.length - 20) : name;

    fileInfo.innerHTML = truncated;

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log(event.target.files);

      this.crewService.uploadImage(formData, (response) => {
        // console.log(response);
        if (response.responsecode == 200) {
          console.log(response);

          this.crewService.formData.profileImage = response.fileurl;
          this.myForm.get("profileImage").setValue(response.fileurl);
        }
      });
      // console.log(this.service.uploadDocumentsDriver);
    }
  }

  uploadSignatureImage(event) {
    const fileInfo = document.querySelector(".file-info1");
    const name = event.target.value.split(/\\|\//).pop();
    const truncated = name.length > 20 ? name.substr(name.length - 20) : name;

    fileInfo.innerHTML = truncated;
    console.log('====event===', event);

    if (event.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      console.log('====uploadImage===', event.target.files);

      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (typeof reader.result === 'string') {
          var base64Data = reader.result.split(',')[1]; // Get the Base64 data after the comma
          console.log("base64 01::", base64Data)
          this.crewService.formData.signatureData = base64Data;
          this.myForm.get("signatureData").setValue(base64Data);
        }
      });

      reader.readAsDataURL(event.target.files[0]);

      this.crewService.uploadImage(formData, (response) => {
        if (response.responsecode == 200) {
          // Remove prefix from fileurl

          const base64Data = response.fileurl.split(',')[1]; // Get the Base64 data after the comma
          console.log("base64 ::", base64Data)
          this.crewService.formData.signatureImage = base64Data;
          this.myForm.get("signatureImage").setValue(base64Data);
        }
      });
    }
  }


  submitHandler() {
    console.log("===submitHandler===", this.myForm);
    if (this.serverValue === 'true' || this.serverValue === true) {
      this.formSubmitted = true;

      // Proceed if the form is valid
      if (this.myForm.valid) {
        const dateVal = this.myForm.get("dateOfBirth").value; // Store date of birth value
        this.formSubmitted = false;
        this.loading = true; // Show loader

        console.log(this.myForm.value);

        // Format date of birth to 'dd-MM-yyyy'
        this.crewService.formData.dateOfBirth = this.datePipe.transform(
          this.crewService.formData.dateOfBirth,
          "dd-MM-yyyy"
        );

        // Check if we are updating an existing record
        if (this.crewService.formData.id) {
          console.log(this.crewService.formData.id);

          // Set company ID if available
          if (this.userCompanyId != null && this.userCompanyId != 0) {
            this.crewService.formData.companyId = this.userCompanyId;
          }

          // Update crew member data
          this.crewService.putData(this.crewService.formData.id, this.crewService.formData, async (data) => {
            console.log(data);

            // Check response code for success
            if (data.responsecode === 200 || data.responsecode === "200") {
              this.successbox.show(); // Show success message
              this.getAllCrewData(this.userCompanyId); // Refresh crew data
              this.resetForm(); // Reset form after submission
              this.myForm.reset(); // Clear form fields
              this.crewService.formData.signatureData = ''; // Reset signature data
            } else {
              // Restore date value if there is an error
              if (dateVal) {
                this.myForm.get("dateOfBirth").setValue(dateVal);
              }
              this.errorbox.text = data.message; // Show error message
              this.errorbox.show();
            }
            this.loading = false; // Hide loader
          });

        } else {
          // Set company ID for a new record
          if (this.userCompanyId != null && this.userCompanyId != 0) {
            this.crewService.formData.companyId = this.userCompanyId;
          }

          // Add a new crew member
          this.crewService.postData(this.crewService.formData, async (response) => {
            console.log(response);

            // Check response code for success
            if (response.responsecode === 200 || response.responsecode === "200") {
              this.successbox.show(); // Show success message
              this.getAllCrewData(this.userCompanyId); // Refresh crew data
              this.resetForm(); // Reset form after submission
              this.myForm.reset(); // Clear form fields
              this.crewService.formData.signatureData = ''; // Reset signature data
            } else {
              // Restore date value if there is an error
              if (dateVal) {
                this.myForm.get("dateOfBirth").setValue(dateVal);
              }
              this.errorbox.text = response.message; // Show error message
              this.errorbox.show();
            }
            this.loading = false; // Hide loader
          });
        }
      }
    } else {
      // Show error if not on shore server
      this.errorbox.text = 'Crew cannot be created from the vessel';
      this.errorbox.show();
    }
  }



  convertToUTCString(dateString) {
    // Split the date string into day, month, and year
    var parts = dateString.split("-");

    // Create a Date object with the given year, month (zero-based), and day
    var date = new Date(parts[2], parts[1] - 1, parts[0]);

    // Get the UTC string representation of the date
    var utcString = date.toUTCString();

    return utcString;
  }

  // getProductDetails(id) {
  //   this.fts.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    window.scroll(0, 0);
    console.log('==onClick==', data);
    if (action == "edit") {
      console.log("anoop check");
      console.log("===data.companyId==", data.companyId);
      if (data.companyId) {
        this.getAllRoleByCompanyId(data.companyId);
      }
      this.crewService.formData.firstName = data.firstName;
      this.crewService.formData.id = data.id;
      // this.isEditMode = true;
      this.crewService.formData.middleName = data.middleName;
      this.crewService.formData.lastName = data.lastName;

      this.crewService.formData.email = data.email;
      this.crewService.formData.userName = data.userName;
      this.crewService.formData.companyId = data.companyId;

      const month = data.dateOfBirth.split("-");
      const newDate = new Date(+month[2], +month[1] - 1, +month[0]);
      console.log(newDate);
      this.crewService.formData.dateOfBirth = newDate.toISOString();

      this.crewService.formData.roleId = data.roleId;

      //this.crewService.formData.dateOfBirth = data.dateOfBirth;
      this.crewService.formData.nationality = data.nationality;
      this.crewService.formData.gender = data.gender;
      this.crewService.formData.active = data.active;

      if (data.profileImage) {
        this.crewService.formData.profileImage = data.profileImage;
      } else {
        this.crewService.formData.profileImage = "assets/media/logo/logo.png";
      }

      if (data.signatureImage) {
        this.crewService.formData.signatureImage = data.signatureImage;
      } else {
        this.crewService.formData.signatureImage = "assets/media/logo/logo.png";
      }

      if (data.signatureData) {
        this.crewService.formData.signatureData = data.signatureData;
      } else {
        this.crewService.formData.signatureData = "";
      }

      this.myForm
        .get("profileImage")
        .setValue(this.crewService.formData.profileImage);
      this.myForm
        .get("signatureImage")
        .setValue(this.crewService.formData.signatureImage);
      this.myForm
        .get("signatureData")
        .setValue(this.crewService.formData.signatureData);
    } else {
      console.log("===deleteData===", data);
      this.crewService.deleteData(data.id).subscribe((data) => {
        console.log("Deleted");
        if (data["responsecode"] != undefined && data["responsecode"] == '200' || data["responsecode"] == 200) {
          this.deletesuccessbox.show();
          this.crewService.getData((response) => {
            console.log(response);
            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
        } else if (data["message"] != undefined) {
          this.errorbox.text = data["message"];
          this.errorbox.show();
        }
      });
    }

    window.scroll(0, 0);
  }

  resetForm() {
    this.crewService.formData.firstName = "";
    this.crewService.formData.id = "";
    // this.isEditMode = false;
    this.crewService.formData.middleName = "";
    this.crewService.formData.lastName = "";
    this.crewService.formData.email = "";
    this.crewService.formData.userName = "";
    this.crewService.formData.dateOfBirth = "";
    this.crewService.formData.roleId = "";
    this.crewService.formData.password = "";

    this.crewService.formData.nationality = "";
    this.crewService.formData.gender = "";

    this.crewService.formData.profileImage = "assets/media/logo/logo.png";
    this.crewService.formData.signatureImage = "assets/media/logo/logo.png";
    this.crewService.formData.signatureData = "";



    if (document.querySelector(".file-info")) {
      const fileInfo = document.querySelector(".file-info");
      fileInfo.innerHTML = "Choose file..";
    }

    if (document.querySelector(".file-info")) {
      const fileInfo1 = document.querySelector(".file-info1");
      fileInfo1.innerHTML = "Choose file..";
    }
    // this.myForm.reset();

    console.log("Here is the Form Data", this.crewService.formData);
  }

  openDialog(data): void {
    console.log("check-data", data)
    if (this.getCrewVesselAssignmentDetail(data.id)) {
      console.log("you can proceed")
      if (data.id) {
        let now = this.datePipe.transform(new Date(), "yyyy-MM-dd") + "T18:30:00.000Z";
        for (var val of this.vesselAssignmentData) {
          if (data.id == val.crewId) {
            if ((val.signinTime <= now && val.signoutTime >= now) || val.signinTime > now) {
              const dialogRef = this.dialog.open(DialogboxComponent, {
                data: {
                  id: val.id,
                  crewId: data.id,
                  vesselId: val.vesselId,
                  crewName: data.firstName + ' ' + (data.middleName ? data.middleName + ' ' : '') + data.lastName,
                  companyName: data.companyName,
                  companyId: data.companyId,
                  showUnassignOnly: true
                },
              });
              this.cdr.detectChanges();
              document.getElementById("button_box").style.display = "none";
              this.dialogboxService.formData.crewId = val.crewId;
              this.dialogboxService.formData.vesselId = val.vesselId;
              this.dialogboxService.formData.signinTime = val.signinTime;
              this.dialogboxService.formData.signoutTime = val.signoutTime;
              this.dialogboxService.formData.disabletime = true;
              dialogRef.afterClosed().subscribe(() => {
                this.ngOnInit();
              });
              break;
            }
          }
        }
      }
    } else {
      console.log("esle is working")
      console.log('check data', data)
      const dialogRef = this.dialog.open(DialogboxComponent, {
        data: {
          crewId: data.id,
          // vesselId: '',
          crewName: `${data.firstName} ${data.middleName ? data.middleName + '' : ''}${data.lastName}`,
          companyName: data.companyName,
          companyId: data.companyId
        }
      });
      document.getElementById("unassign_button_box").style.display = "none";
      this.dialogboxService.formData.crewId = data.id;
      this.dialogboxService.formData.vesselId = "";
      this.dialogboxService.formData.signinTime = "";
      this.dialogboxService.formData.signoutTime = "";
      this.dialogboxService.formData.disabletime = false;
      dialogRef.afterClosed().subscribe(() => {
        this.ngOnInit();
      });
    }
  }

  // openDialog(data): void {
  //   console.log('check data', data)
  //   const dialogRef = this.dialog.open(DialogboxComponent, {
  //     data: { crewId: data.id,
  //       // vesselId: '',
  //       crewName: `${data.firstName} ${data.middleName ? data.middleName + '' : ''}${data.lastName}`,
  //       companyName : data.companyName,
  //       companyId: data.companyId
  //      }
  //   });
  //   document.getElementById("unassign_button_box").style.display = "none";
  //   this.dialogboxService.formData.crewId = data.id;
  //   this.dialogboxService.formData.vesselId = "";
  //   this.dialogboxService.formData.signinTime = "";
  //   this.dialogboxService.formData.signoutTime = "";
  //   this.dialogboxService.formData.disabletime = false;
  //   dialogRef.afterClosed().subscribe(() => {
  //     this.ngOnInit();
  //   });
  // }

  getAllCrewVesselAssignment() {
    this.crewService.getAllCrewVesselAssignmentData((response) => {
      this.vesselAssignmentData = response.data;
      console.log('vesselAssignmentData-24:', this.vesselAssignmentData);
    });
  }

  getCrewVesselAssignmentByCrewId(crew: any) {
    console.log('Vessel Data Here', this.vesselAssignmentData);
    console.log('crew==', crew);

    if (crew.id) {
      let now = this.datePipe.transform(new Date(), "yyyy-MM-dd") + "T18:30:00.000Z";
      for (var val of this.vesselAssignmentData) {
        if (crew.id == val.crewId) {
          if ((val.signinTime <= now && val.signoutTime >= now) || val.signinTime > now) {
            const dialogRef = this.dialog.open(DialogboxComponent, {
              data: {
                id: val.id,
                crewId: crew.id,
                vesselId: val.vesselId,
                crewName: crew.firstName + ' ' + (crew.middleName ? crew.middleName + ' ' : '') + crew.lastName,
                companyName: crew.companyName,
                companyId: crew.companyId,
                showUnassignOnly: true
              },
            });
            this.cdr.detectChanges();
            document.getElementById("button_box").style.display = "none";
            this.dialogboxService.formData.crewId = val.crewId;
            this.dialogboxService.formData.vesselId = val.vesselId;
            this.dialogboxService.formData.signinTime = val.signinTime;
            this.dialogboxService.formData.signoutTime = val.signoutTime;
            this.dialogboxService.formData.disabletime = true;
            dialogRef.afterClosed().subscribe(() => {
              this.ngOnInit();
            });
            break;
          }
        }
      }
    }
  }



  getCrewVesselAssignmentDetail(crewId: any) {
    console.log("==crewId==", crewId);
    if (crewId) {
      let now = this.datePipe.transform(new Date(), "yyyy-MM-dd") + "T18:30:00.000Z";
      console.log("==this.vesselAssignmentData==", this.vesselAssignmentData);
      console.log("==now==", now);
      if (this.vesselAssignmentData.length > 0) {
        for (var val of this.vesselAssignmentData) {
          if (crewId == val.crewId) {
            console.log("==val==", val);
            //alert("--crewId--"+val.crewId+"--now--"+now+"--signinTime--"+val.signinTime+"--signoutTime--"+val.signoutTime);
            if (
              (val.signinTime <= now && val.signoutTime >= now) ||
              val.signinTime > now
            ) {
              return 1;
            }
          }
        }
      }
    }
    return 0;
  }

  getSignature(imagedata: string) {
    return imagedata != "" && imagedata != null
      ? this.domSanitizer.bypassSecurityTrustUrl(
        "data:image/jpg;base64," + imagedata
      )
      : this.domSanitizer.bypassSecurityTrustUrl(
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABQUlEQVRYhe3WTytEYRTH8Q+NNERZmFIWlhQrspK1LUuy8C5EsmFnyxvwHpQsrZSVWVhYSA01CxulFA2LeYY7/tzM4xoW91en+3See875du7TPQ+5/rGeI+wcQ38JkClEI2EMcCYQsQDlxLP0FwCDOJNBJ2IBZAVxhLkIgM+s/FVQRwxZCkCaWq5VwCYucYUtdLXO1fKnfNWGj63cbifARQicxXRYV9oJcB8Ce36YKDWuMyJhpsoBcoAcoNCGGqkz4DsdKAaL0QAWI2OdqP/B9rDj87Fawi6quMFCYq+I4xC3FgMwjgfU8IRHTCX2V3CreVg1ZkU/DoPvCsMxALDu4yTsxX7Cf4DJsK5hxtu9sIqx2OLUD+lpSNgdfMsh+R2WEu9ea+5GGSM/Kd7QhObWwypG3/nmA0RF/cz0ZVE816/rBQZFh/X/XGGHAAAAAElFTkSuQmCC"
      );
  }

  openPopup(signatureData: string): void {
    console.log('signature', signatureData)
    event.preventDefault()
    this.signatureUrl = signatureData;
    console.log("signatureUrl", this.signatureUrl)
    // Open the dialog and pass the data
    this.dialog.open(this.signaturePopup, {
      data: { signatureUrl: this.signatureUrl }
      // width: '600px'
    });
  }

  closePopup(): void {
    this.dialog.closeAll();
  }
}
