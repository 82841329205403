import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout2',
  templateUrl: './layout2.component.html',
  styleUrls: ['./layout2.component.scss']
})
export class Layout2Component implements OnInit {

  constructor() { }

  ngOnInit() {

    console.log('dfsjdfh');
  }

}
