import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';
import { FieldTypeComponent } from './field-type/field-type.component';
import { WatchHourComponent } from './watch-hour/watch-hour.component';
import { SailingModesComponent } from './sailing-modes/sailing-modes.component';
import { SailingModesChangeLogComponent } from './sailing-modes-change-log/sailing-modes-change-log.component';
import { HierarchyComponent } from './hierarchy/hierarchy.component';
import { SectionsComponent } from './sections/sections.component';
import { ReportsComponent } from './reports/reports.component';
import { DownloadReportComponent } from './reports/download-report/download-report.component';
import { CargoarrangementComponent } from './cargoarrangement/cargoarrangement.component';
import { CospComponent } from './cosp/cosp.component';
import { EospComponent } from './eosp/eosp.component';
import { CompanyComponent } from './company/company.component';
import { VoyagelistComponent } from './voyagelist/voyagelist.component';
import { FormsComponent } from './forms/forms.component';
import { VesselsComponent } from './vessels/vessels.component';
import { VesseltypeComponent } from './vesseltype/vesseltype.component';
import { CrewComponent } from './crew/crew.component';
import { RolesComponent } from './roles/roles.component';
import { RolesandrightsComponent } from './rolesandrights/rolesandrights.component';

import { WorkflowComponent } from './workflow/workflow.component';

import { ValidationComponent } from './validation/validation.component';

import { FieldvalidationComponent } from './fieldvalidation/fieldvalidation.component';
import { FormworkflowComponent } from './formworkflow/formworkflow.component';
import { WorkflowactionComponent } from './workflowaction/workflowaction.component';

import { LoginComponent } from './Authentication/login/login.component';

import { FormfieldsComponent } from './formfields/formfields.component';
import { Layout1Component } from './layout1/layout1.component';
import { Layout2Component } from './layout2/layout2.component';
import { AuthGuardService } from './auth-guard.service';
import { FormbuilderComponent } from './formbuilder/formbuilder.component';
import { HierarchyTreeComponent } from './hierarchy-tree/hierarchy-tree.component';
import { ORBCodeComponent } from './orbcode/orbcode.component';
import { ORBCodeItemComponent } from './orbcode-item/orbcode-item.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { ChecklistvesselsComponent } from './checklistvessels/checklistvessels.component';
import { QuestionComponent } from './question/question.component';
import { AddnoteComponent } from './addnote/addnote.component';
import { ReleasenoteComponent } from './releasenote/releasenote.component';
import { ChecklistreportsComponent } from './checklistreports/checklistreports.component';
import { EmailchecklistreportsComponent } from './emailchecklistreports/emailchecklistreports.component';
import { FormreportsComponent } from './formreports/formreports.component';
import { FormaddComponent } from './formadd/formadd.component';
import { ManageFormreportsComponent } from './formreports/manage-formreports/manage-formreports.component';
import {FormlistComponent} from './formlist/formlist.component';
import { FormlistvesselsComponent } from './formlistvessels/formlistvessels.component';
import { LogBookFormsComponent } from './log-book-forms/log-book-forms.component';
import { EditlogbookformsComponent } from './editlogbookforms/editlogbookforms.component';
import { LandingboardComponent } from './landingboard/landingboard.component';
import { QuestionformsComponent } from './questionforms/questionforms.component';
import { AddformsnotesComponent } from './addformsnotes/addformsnotes.component';
import { CospalertComponent } from './cospalert/cospalert.component';
import { SignoffreportsComponent } from './signoffreports/signoffreports.component';
import { SignoffsheetComponent } from './signoffsheet/signoffsheet.component';
import { StatussheetreportComponent } from './statussheetreport/statussheetreport.component';
import { StatussheetComponent } from './statussheet/statussheet.component';
import { EmailreportsComponent } from './emailreports/emailreports.component';
import { EmailformreportsComponent } from './emailformreports/emailformreports.component';



const routes: Routes = [
  //{ path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', component:Layout1Component,children:[{path:'',component:LoginComponent}]},
  { path: 'login', component:Layout1Component,children:[{path:'',component:LoginComponent}]},
  { path: 'dashboard1', component:Layout2Component,children:[{path:'',component:DashboardComponent}], canActivate: [AuthGuardService]},
  { path: 'hierarchy', component:Layout2Component,children:[{path:'',component:HierarchyComponent}], canActivate: [AuthGuardService]},
  { path: 'hierarchytree', component:Layout2Component,children:[{path:'',component:HierarchyTreeComponent}], canActivate: [AuthGuardService]},
    {path:'users',component:Layout2Component,children:[{path:'',component:UserComponent}], canActivate: [AuthGuardService]},
    {path:'fieldtypes',component:Layout2Component,children:[{path:'',component:FieldTypeComponent}], canActivate: [AuthGuardService]},
    {path:'watchhours',component:Layout2Component,children:[{path:'',component:WatchHourComponent}], canActivate: [AuthGuardService]},
    {path:'sailingmodes',component:Layout2Component,children:[{path:'',component:SailingModesComponent}], canActivate: [AuthGuardService]},
    {path:'sailingmodelogs',component:Layout2Component,children:[{path:'',component:SailingModesChangeLogComponent}], canActivate: [AuthGuardService]},
    {path:'sections',component:Layout2Component,children:[{path:'',component:SectionsComponent}], canActivate: [AuthGuardService]},
    {path:'reports',component:Layout2Component,children:[{path:'',component:ReportsComponent}], canActivate: [AuthGuardService]},
    {path:'downloadreport', children:[{path:'',component:DownloadReportComponent}]},
    {path:'emailreports/:hid/:formId/:vyogeno/:date/:watchours/:bname/:vesselId',component:Layout1Component,children:[{path:'',component:EmailreportsComponent}]},
    {path:'cargoarrangement',component:Layout2Component,children:[{path:'',component:CargoarrangementComponent}], canActivate: [AuthGuardService]},

    {path:'cosp',component:Layout2Component,children:[{path:'',component:CospComponent}], canActivate: [AuthGuardService]},
    {path:'vessel',component:Layout2Component,children:[{path:'',component:EospComponent}], canActivate: [AuthGuardService]},
    {path:'company',component:Layout2Component,children:[{path:'',component:CompanyComponent}], canActivate: [AuthGuardService]},
    {path:'voyagelist',component:Layout2Component,children:[{path:'',component:VoyagelistComponent}], canActivate: [AuthGuardService]},
    {path:'forms',component:Layout2Component,children:[{path:'',component:FormsComponent}], canActivate: [AuthGuardService]},
    {path:'vessels',component:Layout2Component,children:[{path:'',component:VesselsComponent}], canActivate: [AuthGuardService]},
    {path:'vesseltype',component:Layout2Component,children:[{path:'',component:VesseltypeComponent}], canActivate: [AuthGuardService]},
    {path:'crew',component:Layout2Component,children:[{path:'',component:CrewComponent}], canActivate: [AuthGuardService]},
    {path:'roles',component:Layout2Component,children:[{path:'',component:RolesComponent}], canActivate: [AuthGuardService]},
    {path:'rolesandrights',component:Layout2Component,children:[{path:'',component:RolesandrightsComponent}], canActivate: [AuthGuardService]},

    {path:'workflow',component:Layout2Component,children:[{path:'',component:WorkflowComponent}], canActivate: [AuthGuardService]},
    {path:'formworkflow',component:Layout2Component,children:[{path:'',component:FormworkflowComponent}], canActivate: [AuthGuardService]},
    {path:'workflowaction',component:Layout2Component,children:[{path:'',component:WorkflowactionComponent}], canActivate: [AuthGuardService]},

    {path:'validations',component:Layout2Component,children:[{path:'',component:ValidationComponent}], canActivate: [AuthGuardService]},
    {path:'fieldvalidation',component:Layout2Component,children:[{path:'',component:FieldvalidationComponent}], canActivate: [AuthGuardService]},
    {path:'formfield',component:Layout2Component,children:[{path:'',component:FormfieldsComponent}], canActivate: [AuthGuardService]},
    {path:'formbuilder',component:Layout2Component,children:[{path:'',component:FormbuilderComponent}], canActivate: [AuthGuardService]},
    {path:'orbcode',component:Layout2Component,children:[{path:'',component:ORBCodeComponent}], canActivate: [AuthGuardService]},
    {path:'orbcodeitem',component:Layout2Component,children:[{path:'',component:ORBCodeItemComponent}], canActivate: [AuthGuardService]},
    {path:'checklist',component:Layout2Component,children:[{path:'',component:ChecklistComponent}], canActivate: [AuthGuardService]},
    {path:'checklistvessels',component:Layout2Component,children:[{path:'',component:ChecklistvesselsComponent}], canActivate: [AuthGuardService]},
    {path:'question',component:Layout2Component,children:[{path:'',component:QuestionComponent}], canActivate: [AuthGuardService]},
    {path:'notes',component:Layout2Component,children:[{path:'',component:AddnoteComponent}], canActivate: [AuthGuardService]},
    {path:'release-note',component:Layout2Component,children:[{path:'',component:ReleasenoteComponent}], canActivate: [AuthGuardService]},
    {path:'checklistreports',component:Layout2Component,children:[{path:'',component:ChecklistreportsComponent}], canActivate: [AuthGuardService]},
    {path:'emailchecklistreports/:vesselId/:checkListName/:checklistId/:actionDate/:actionTime',component:Layout1Component,children:[{path:'',component:EmailchecklistreportsComponent}]},
    {path:'logbookforms',component:Layout2Component,children:[{path:'',component:LogBookFormsComponent}], canActivate: [AuthGuardService]},
    {path:'editlogbookforms',component:Layout2Component,children:[{path:'',component:EditlogbookformsComponent}], canActivate: [AuthGuardService]},
    {path:'dashboard',component:Layout2Component,children:[{path:'',component:LandingboardComponent}], canActivate: [AuthGuardService]},
    {path:'formlist',component:Layout2Component,children:[{path:'',component:FormlistComponent}], canActivate: [AuthGuardService]},
    {path:'formreports',component:Layout2Component,children:[{path:'',component:FormreportsComponent}], canActivate: [AuthGuardService]},
    {path:'addform',component:Layout2Component,children:[{path:'',component:FormaddComponent}], canActivate: [AuthGuardService]},
    // {path:'manage-formreports',component:Layout2Component,children:[{path:'',component:ManageFormreportsComponent}], canActivate: [AuthGuardService]},
    {path:'manage-formreports',component:Layout2Component,children:[{path:'',component:ManageFormreportsComponent}], canActivate: [AuthGuardService]},
    {path:'emailformreports/:vesselId/:formsName/:formlistId/:actionDate/:actionTime',component:Layout1Component,children:[{path:'',component:EmailformreportsComponent}]},
    {path:'formlistvessels',component:Layout2Component,children:[{path:'',component:FormlistvesselsComponent}], canActivate: [AuthGuardService]},
    {path:'questionforms',component:Layout2Component,children:[{path:'',component:QuestionformsComponent}], canActivate: [AuthGuardService]},
    {path:'formnotes',component:Layout2Component,children:[{path:'',component:AddformsnotesComponent}], canActivate: [AuthGuardService]},
    {path:'cospalert',component:Layout2Component,children:[{path:'',component:CospalertComponent}], canActivate: [AuthGuardService]},
    {path:'signoffreports',component:Layout2Component,children:[{path:'',component:SignoffreportsComponent}], canActivate: [AuthGuardService]},
    {path:'signoffsheet',component:Layout2Component,children:[{path:'',component:SignoffsheetComponent}], canActivate: [AuthGuardService]},
    {path:'statussheetreport',component:Layout2Component,children:[{path:'',component:StatussheetreportComponent}], canActivate: [AuthGuardService]},
    {path:'statussheet',component:Layout2Component,children:[{path:'',component:StatussheetComponent}], canActivate: [AuthGuardService]},



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
