import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  formData = { bookTypeId: '' };


  constructor(public http: HttpClient, public service: ApiRootService) { }


  public getParentCategory(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getLogBookDetailsById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFolderWithChildInTree').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }


  public getFormByHid(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllFormBuilder').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetails(ids, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNo/' + ids + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetailsByIds(ids, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoGroupByActionDateTime/' + ids + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDataWithAllRecordsByVoyageNoWithFromToDateTime(ids, voyageNo, fromDate, toDate, callback) {
    return this.http.get(this.service.getBaseUrl() + '/v1/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/' + ids + '/' + voyageNo + '/' + fromDate + '/' + toDate + '/null').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getFormDetailsByActionDateAndTime(ids, voyageNo, actionDate = '', actionTime = '', fromDate = '', toDate = '', callback) {
    //alert(actionDate+"===="+actionTime+'====='+fromDate+'========='+toDate);
    if (fromDate) {
      if (toDate == '' || toDate == null) {
        toDate = 'NoDate';
      }
      if (actionTime == '' || actionTime == null) {
        actionTime = 'NoTime';
      }
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/'+ids+'/'+voyageNo+'/'+fromDate+'/'+toDate+'/'+actionTime);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithFromToDateTime/' + ids + '/' + voyageNo + '/' + fromDate + '/' + toDate + '/' + actionTime).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });

    }
    else if (actionTime && actionTime != '') {
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/'+ids+'/'+voyageNo+'/'+actionDate+'/'+actionTime);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithActionDateTime/' + ids + '/' + voyageNo + '/' + actionDate + '/' + actionTime).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });
    }



    else {
      //alert(this.service.getBaseUrl()+'/getFormDataWithAllRecordsByVoyageNoWithActionDate/'+ids+'/'+voyageNo+'/'+actionDate);
      return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNoWithActionDate/' + ids + '/' + voyageNo + '/' + actionDate).subscribe(
        (data) => {
          console.log(data);
          callback(data);
        });
    }

  }

  public getFormDetailsById(id, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getFormDataWithAllRecordsByVoyageNo/' + id + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getAllActiveWatchHour(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getAllWatchHourWithActive').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }


  public getWatchHourById(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getDLBWatchHourById/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      });

  }

  public getLatestCOSPByPasscode(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getLatestCOSPByPasscode/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public updateRankSignatureByFormAndRecordId(logbookAuthorizedBy, callback) {
    return this.http.post(this.service.getBaseUrl() + '/v1/updateRankSignatureByFormAndRecordId', logbookAuthorizedBy).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public updateRankSignatureForDeckByFormAndRecordId(logbookAuthorizedBy, callback) {
    return this.http.post(this.service.getBaseUrl() + '/updateRankSignatureForDeckByFormAndRecordId/', logbookAuthorizedBy).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getCrewSignByCrewId(id, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCrewByCrewId/' + id).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getCOSPByvesselId(vesselId, voyageNo, callback) {
    return this.http.get(this.service.getBaseUrl() + '/getCOSPByvesselId/' + vesselId + '/' + voyageNo).subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

  public getSettingDetail(callback) {
    return this.http.get(this.service.getBaseUrl() + '/getSettingDetail').subscribe(
      (data) => {
        console.log(data);
        callback(data);
      }
    )
  }

}
