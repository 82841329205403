import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormArray, RequiredValidator } from '@angular/forms';
import { QuestionsectionComponent } from '../questionsection/questionsection.component';
//import { QuestionsectionService } from '../questionsection/questionsection.service';
import { QuestionsectionformsService } from '../questionsectionforms/questionsectionforms.service';
import { QuestionformsService } from './questionforms.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { QuestionsectionformsComponent } from '../questionsectionforms/questionsectionforms.component';

@Component({
  selector: 'app-questionforms',
  templateUrl: './questionforms.component.html',
  styleUrls: ['./questionforms.component.scss']
})
export class QuestionformsComponent implements OnInit {

  formData: any = [];
  rows: FormArray;
  itemForm: any = [];
  sectionData: any = [];
  formsId: any;
  sectionId: any;
  showEdit: any;
  htmlControls = [];
  dataExists: any;
  newSectionId: any;
  newSectionName: any;


  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;


  constructor(private router: Router, public qs: QuestionformsService, private formBuilder: FormBuilder, public session: SessionStorageService, private dialog: MatDialog, private questionsectionformsService: QuestionsectionformsService) {
    this.rows = this.formBuilder.array([]);
  }

  ngOnInit() {
    this.rows = this.formBuilder.array([]);
    this.formsId = this.session.get('formlistId');
    console.log(this.formsId);
    this.sectionId = 1;

    this.qs.formData.section_id = this.sectionId;
    this.formData = this.formBuilder.group({
      sectionId: new FormControl(null)

    })
    this.qs.getAllSections((response) => {
      console.log(response);
      this.sectionData = [];
      this.sectionData = response.data;
      console.log('===getAllSections==');
      console.log(this.sectionData);
      console.log(this.sectionData.length);
      this.dataExists = false;
      this.addFirstRow();
      if (this.formsId) {
        this.qs.getQuestionDataOfFormlistId(this.formsId, (response) => {
          console.log(response.data);
          if (response.data) {
            this.dataExists = true;
            this.addDataRowArrangement(response.data.length, response.data);

          }

        })

      }
    })


    this.qs.getControlTypeData((response) => {
      console.log(response);
      this.htmlControls = response.data;
      // this.dataSource = new MatTableDataSource(response.data);
    })

    this.formData.addControl('rows', this.rows);

    console.log("====section data=======")
    console.log(this.sectionData);

  }

  addFirstRow() {

    this.onAddSectionArrangement(1);
  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      var x = a[key]; var y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  getSectionName(id) {
    for (var r of this.sectionData) {
      if (r.id == id) {
        return r.formsectionName;
      }
      console.log(this.sectionData);
      console.log(r.formsectionName);
    }
  }

  submitHandler() {
    console.log("before save");
    this.itemForm = [];
    console.log("form-data::", this.formData);

    if (this.formData.valid) {
      console.log(this.formData);

      // Loop through all rows and ensure required fields are filled
      let hasEmptyRow = false;

      // Iterate over each row in 'rows' FormArray
      for (let row of this.formData.get('rows').controls) {
        const questiondetailsControl = row.get('questiondetails');
        const controlTypeControl = row.get('controlType');

        if ((questiondetailsControl && questiondetailsControl.invalid) || (controlTypeControl && controlTypeControl.invalid)) {
          hasEmptyRow = true;
          if (questiondetailsControl) questiondetailsControl.markAsTouched(); // Mark as touched to show validation errors
          if (controlTypeControl) controlTypeControl.markAsTouched(); // Mark as touched to show validation errors
        } else {
          this.itemForm.push(row.value);
        }
      }

      // If there are any empty or invalid rows, show an error message and prevent saving
      if (hasEmptyRow) {
        this.errorbox.text = 'Please fill required value before save';
        this.errorbox.show();
        return; // Stop submission if there are invalid rows
      }

      // Continue with saving if there are valid rows
      if (this.itemForm.length > 0) {
        this.qs.saveFormListData(this.itemForm, (response) => {
          console.log(response);
          if (response.responsecode === 200 || response.responsecode === '200') {
            this.successbox.show();
            this.ngOnInit();  // Refresh or reset the form after successful save
          } else {
            this.errorbox.text = response.message;
            this.errorbox.show();
          }
        });
      }
      console.log(this.itemForm);
    } else {
      // If the entire form is invalid (other than rows), show a generic error
      this.errorbox.text = 'Please fill required value before save';
      this.errorbox.show();
    }
  }

  onAddRowArrangement(sectionid: any) {
    if (sectionid != 0) {
      console.log("sectionid-onAddRowArrangement", sectionid);

      // Find the first row for the section
      let firstRowIndex = -1;
      for (let r = 0; r < this.rows.controls.length; r++) {
        if (this.rows.controls[r]['controls']['sectionId'].value == sectionid) {
          firstRowIndex = r;
          break;
        }
      }

      // Check if the first row is filled
      if (firstRowIndex !== -1) {
        const firstRow = this.rows.controls[firstRowIndex];
        const questiondetailsControl = firstRow.get('questiondetails');
        const controlTypeControl = firstRow.get('controlType');

        if (!questiondetailsControl.value || !controlTypeControl.value) {
          // Show validation message if the first row is not filled
          this.errorbox.text = 'Please fill out the first row before adding another question.';
          this.errorbox.show();
          return; // Stop adding a new row
        }
      }

      // Proceed with adding a new row if the first row is filled
      console.log("check-row-control-length", this.rows.controls.length);
      console.log("check-row-control", this.rows.controls);

      const sectionName = this.getSectionName(sectionid);
      var arr = {
        'sectionId': sectionid,
        'sectionName': sectionName,
        'formsId': this.formsId,
        'questiondetails': '',
        'controlType': '',
        'controlValue': '',
        'newrow': 1,
        'newsection': 0,
        'hidingsection': 0,
        'id': null
      };
      console.log(arr);

      const newRow = this.createItemFormGroupArrangement(arr);

      // Set validators for new row
      const questiondetailsControlNew = newRow.get('questiondetails');
      if (questiondetailsControlNew) {
        questiondetailsControlNew.setValidators(Validators.required);
      }

      const controlTypeControlNew = newRow.get('controlType');
      if (controlTypeControlNew) {
        controlTypeControlNew.setValidators(Validators.required);
      }

      // Find where to insert the new row
      var arrGroup = [];
      for (let r = 0; r < this.rows.controls.length; r++) {
        if (this.rows.controls[r]['controls']['sectionId'].value == sectionid) {
          arrGroup.push(r + 1);
        }
      }

      // Insert the new row at the correct position
      this.rows.controls.splice(arrGroup[arrGroup.length - 1], 0, newRow);
      console.log("after insert1");
      console.log(this.rows.controls);
    } else {
      this.errorbox.text = 'Please select a question before creating a new field.';
      this.errorbox.show();
    }
  }

  onSectionSelect(index, section_id) {
    for (let r = 0; r < this.rows.controls.length; r++) {
      if (r == index) {
        this.rows.controls[r]['controls']['sectionId'].value == section_id;
      }
    }
    console.log("after insert2")
    console.log(this.rows.controls);
  }

  onAddSectionArrangement(event: any) {
    console.log("Adding new sections...");

    // Default values for new rows
    var arr = {
      'sectionId': 0,
      'sectionName': '',
      'formsId': this.formsId,
      'questiondetails': '',
      'controlType': '',
      'controlValue': '',
      'newrow': 1,
      'newsection': 1,
      'hidingsection': 0,
      'id': null
    };

    // Add new rows
    for (var i = 0; i < event; i++) {
      const newRow = this.createItemFormGroupArrangement(arr);

      // Add the new row to the form array
      this.rows.push(newRow);
    }

    console.log("after insert3");
    console.log(this.rows);
  }


  addDataRowArrangement(count, arr: any) {
    if (this.formData.value.rows[0].newrow == 1) {
      this.rows.removeAt(0);
    }
    for (var i = 0; i < count; i++) {
      this.rows.push(this.createItemFormGroupArrangement(arr[i]));

    }
    console.log("after insert4")
    console.log(this.rows.controls);
  }

  createItemFormGroupArrangement(datas: any): FormGroup {
    var hidesection = 0;
    for (let t = 0; t < this.formData.value.rows.length; t++) {
      if (this.formData.value.rows[t].sectionId == datas.sectionId) {
        hidesection = 1;
      }
    }

    var hideControlType = ["Radio", "Checkbox", "DropDown"];
    datas.hidecontrolValue = 1;
    var controlTypeValue = datas.controlType;
    if (hideControlType.indexOf(controlTypeValue) < 0) {
      datas.hidecontrolValue = 0;
    }

    return this.formBuilder.group({
      formsId: (datas.formsId),
      sectionId: [datas.sectionId, [Validators.required, Validators.min(1)]],
      sectionName: (datas.sectionName),
      questiondetails: [datas.questiondetails, Validators.required],
      controlValue: (datas.controlValue),
      controlType: [datas.controlType, Validators.required],
      newrow: (datas.newrow ? datas.newrow : 0),
      newsection: (datas.newsection ? datas.newsection : 0),
      hidesection: (hidesection),
      hidecontrolValue: (datas.hidecontrolValue),
      id: (datas.id ? datas.id : null)
    });

  }

  onRemoveRowArrangement(rowIndex: number) {
    if (this.formData.value.rows[rowIndex].id) {
      console.log(this.formData.value.rows[rowIndex].id);
      this.qs.deleteData(this.formData.value.rows[rowIndex].id).subscribe((data) => {
        console.log('Deleted');
        console.log(data);
        this.ngOnInit();
      })
    }

    this.rows.removeAt(rowIndex);
    if (this.rows.length <= 0) {
      this.router.navigate(['/formlist']);
    }
  }

  updateRowArrangement(rowIndex: number) {
    if (this.formData.value.rows[rowIndex].newrow == 0) {
      //let index = (this.sectionData as FormArray).findIndex(x => x.id == this.formData.value.rows[rowIndex].sectionId);
      this.formData.value.rows[rowIndex].sectionName = this.getSectionName(this.formData.value.rows[rowIndex].sectionId)
      console.log('==updateRowArrangement==');
      console.log(this.formData.value.rows[rowIndex]);
      this.qs.updateQuestionDetails(this.formData.value.rows[rowIndex].id, this.formData.value.rows[rowIndex], (data) => {
        console.log(data);
        if (data.responsecode === 200 || data.responsecode === '200') {
          this.successbox.show();
          this.ngOnInit();
        } else {
          this.errorbox.text = data.message;
          this.errorbox.show();
        }
      })
    }
    this.showEdit = -1;
  }

  editRowArrangement(val, rowIndex: number) {
    var contorlType;
    if (this.rows.controls[rowIndex]['controls']['newrow'].value == 0) {
      this.showEdit = rowIndex;
    }
    contorlType = this.rows.controls[rowIndex]['controls']['controlType'].value
    var hideControlType = ["Radio", "Checkbox", "DropDown"];
    if (hideControlType.indexOf(contorlType) < 0) {

      this.rows.controls[rowIndex]['controls']['hidecontrolValue'].value = 0;
    } else {
      this.rows.controls[rowIndex]['controls']['hidecontrolValue'].value = 1;
    }
  }

  onFieldValueChange(rowIndex: number, oldSectionId: number) {

    console.log("Old Section ID:", oldSectionId);
    console.log("Form Rows:", this.formData.value.rows);

    this.newSectionId = this.formData.value.rows[rowIndex].sectionId;
    this.newSectionName = this.getSectionName(this.newSectionId);
    console.log("New Section ID:", this.newSectionId);
    console.log("New Section Name:", this.newSectionName);

    this.formData.value.rows[rowIndex].sectionName = this.newSectionName;

    if (oldSectionId == 0) {
      console.log("coming because oldsection id is equal to zero")
      oldSectionId = this.newSectionId;
    }

    console.log("new-section-id store in old one if oldsectionid == 0", oldSectionId)

    this.formData.value.rows.forEach((row, i) => {
      console.log("check-row", row, "check-i-value", i)
      // if (i === rowIndex || row.sectionId === oldSectionId) {
      if (row.sectionId === oldSectionId) {
        console.log(`Updating row ${i} from old Section ID: ${oldSectionId} to new Section ID: ${this.newSectionId}`);


        this.formData.controls.rows.controls[i].patchValue({ sectionId: this.newSectionId, sectionName: this.newSectionName });
      }
    });

    console.log("Form Rows after", this.formData.value.rows);

    if (this.formData.value.rows[rowIndex].newrow == 0) {
      this.showEdit = rowIndex;
    }
  }

  backButton() {
    this.router.navigate(['/formlist']);
  }

  openDialog(): void {
    this.questionsectionformsService.formData.formsectionName = '';
    this.questionsectionformsService.formData.formsectiondetails = '';
    const dialogRef = this.dialog.open(QuestionsectionformsComponent, {
      data: { sectionId: '' }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.qs.getAllSections((response) => {
        console.log(response);
        this.sectionData = [];
        this.sectionData = response.data;
      });
    });
  }

  onSectionChange(event) {
    alert('-----');
    console.log('===event===');
    console.log(event);
  }


}
