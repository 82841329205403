import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRootService } from '../apiroot.service';

@Injectable({
  providedIn: 'root'
})
export class RolesandrightsService {

  formData = {id:'',roleId:'',companyId:'',rolePermission:'',roleDetails:''}

  constructor(public http:HttpClient,public service:ApiRootService) { }

  getPermissionGroup(idAdmin=1){
      if(idAdmin){
      return [
      {"id":"1","groupName":"Super Admin" , "permission":"dashboard,hierarchy,hierarchytree,watchhours,sailingmodes,sailingmodelogs,sections,forms,formfield,vessels,crew,roles,rolesandrights,workflow,formworkflow,workflowaction,validations,fieldvalidation,formbuilder,reports,orbcode,orbcodeitem,cosp,voyagelist,checklist,checklistreports,company,formreports,formlist,logbookforms,editlogbookforms,signoffreports"},
      {"id":"2","groupName":"Admin" , "permission":"dashboard,watchhours,sailingmodes,sailingmodelogs,vessels,crew,roles,rolesandrights,reports,cosp,voyagelist,checklist,checklistreports,formreports,formlist,logbookforms,editlogbookforms,signoffreports"},
      {"id":"3","groupName":"User" , "permission":"dashboard,reports,cosp,voyagelist,checklistreports,checklist,formreports,formlist,logbookforms,editlogbookforms,signoffreports"},
      {"id":"4","groupName":"Custom" , "permission":"dashboard"}
      ]
      }else{

      return [
      //{"id":"1","groupName":"Super Admin" , "permission":"dashboard,hierarchy,hierarchytree,watchhours,sailingmodes,sailingmodelogs,sections,forms,formfield,vessels,crew,roles,rolesandrights,workflow,formworkflow,workflowaction,validations,fieldvalidation,formbuilder,reports,orbcode,orbcodeitem,eosp,cosp,voyagelist,checklistreports,checklist,company,formreports"},
      {"id":"2","groupName":"Admin" , "permission":"dashboard,watchhours,sailingmodes,sailingmodelogs,vessels,crew,roles,rolesandrights,reports,cosp,voyagelist,checklist,checklistreports,formreports,formlist,logbookforms,editlogbookforms,signoffreports"},
      {"id":"3","groupName":"User" , "permission":"dashboard,reports,cosp,voyagelist,checklistreports,checklist,formreports,formlist,logbookforms,editlogbookforms,signoffreports"},
      {"id":"4","groupName":"Custom" , "permission":"dashboard"}
      ]

      }
  }

  getAllControllers(){
      return ['dashboard','hierarchy','hierarchytree','watchhours','sailingmodes','sailingmodelogs','sections','forms','formfield','vessels','crew','roles','rolesandrights','workflow','formworkflow','workflowaction','validations','fieldvalidation','formbuilder','reports','orbcode','orbcodeitem','cosp','voyagelist','checklist','checklistreports','company','formreports','formlist','logbookforms','editlogbookforms','signoffreports','addform']
  }



  postData(formData,callback){
   return this.http.post(this.service.getBaseUrl()+'/saveRolePermissionDetails', formData).subscribe(
      (data) => {
        console.log(data);
        callback(data);
    });

  }

  getData(callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllRolePermissionDetails').subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

    getAllRolePermissionDetailsByCompanyId(id,callback){
    return this.http.get(this.service.getBaseUrl()+'/getAllRolePermissionDetailsByCompanyId/'+id).subscribe(
      (data) =>{
        console.log(data);
        callback(data);
      }
    )}

    // getDataById(id,formData,callback){
    //   return this.http.get('http://192.168.0.2:8087/campbellservice/getfieldTypeByfieldTypeId/'+id,formData).subscribe(
    //     (data) =>{
    //       // console.log(data);
    //       callback(data);
    //     }
    //   )}

    getDataById(id,callback){
      return this.http.get(this.service.getBaseUrl()+'/getRoleFormRightsByRoleFormRightsId/'+id).subscribe(
        (data) =>{
          // console.log(data);
          callback(data);
        }
      )}

    putData(id,formData,callback){
      return this.http.put(this.service.getBaseUrl()+'/updateRolePermissionDetails/'+id,formData).subscribe((data)=>{
        console.log(data);
        callback(data);
    }
  )}

  deleteData(id){
    return this.http.delete(this.service.getBaseUrl()+'/softDeleteRolePermissionDetailsByid/'+id);
  }
}
