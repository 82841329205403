import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule, FormArray } from '@angular/forms';
import { QuestionsectionComponent } from '../../questionsection/questionsection.component';
//import { QuestionsectionService } from '../questionsection/questionsection.service';
import { QuestionsectionformsService } from '../../questionsectionforms/questionsectionforms.service';
import { QuestionformsService } from '../../questionforms/questionforms.service';
import { HttpClient } from '@angular/common/http';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SessionStorageService } from 'angular-web-storage';
import { ActivatedRoute, RouterModule, Routes, Router } from '@angular/router';
import { FormreportsService } from '../formreports.service';
import { CrewService } from "src/app/crew/crew.service";
import { DatePipe } from "@angular/common";
// import { QuestionsectionformsComponent } from '../questionsectionforms/questionsectionforms.component';

@Component({
  selector: 'report-formpage',
  templateUrl: './formpage.component.html',
  styleUrls: ['./formpage.component.scss']
})
export class FormpageComponent implements OnInit {
  @Input() formData: any;
  @Output() updateParent = new EventEmitter();
  // @Output() completionStatusChanged: EventEmitter<boolean> = new EventEmitter();
  dataForEdit: any;
  dynamicForm: FormGroup;
  form: FormGroup;
  isDataBind: boolean = false;
  isAddNewQuestion: boolean;
  formansweradditioninfomodel: any = [];
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;
  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;
  @ViewChild('noData', { static: true }) public noData: SwalComponent;
  @ViewChild('approval', { static: true }) public approval: SwalComponent;
  items: any[] = ['item1', 'item2', 'item3'];
  fileFormData: FormData;
  selectedVesselId: number;
  voyageNo: any;
  userId: any;
  currentActionDate: any;
  currentActionTime: any;
  authurl: any = this.session.get('Authurl');
  count: number = 0;
  imageItem: any[] = [];
  loading; boolean;
  confirmdetail: boolean;

  constructor(private router: Router, private route: ActivatedRoute, public qs: QuestionformsService, private formBuilder: FormBuilder, public session: SessionStorageService, private dialog: MatDialog, private questionsectionformsService: QuestionsectionformsService, public formreportsService: FormreportsService, public crewService: CrewService, public datePipe: DatePipe,) {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
  }

  ngOnInit() {

    let currentDate = new Date();
    this.userId = this.session.get('userid');

    if (currentDate) //audits inspections
    {
      this.currentActionDate = (this.datePipe.transform(currentDate, 'dd-MMM-yyyy'));
      this.currentActionTime = (this.datePipe.transform(currentDate, 'HH:mm'));
    }

    // this.items.forEach((item, index) => {
    //   this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
    // });

    if (this.formData.isAddNewQuestion && this.formData.id) {
      this.isAddNewQuestion = this.formData.isAddNewQuestion;
      this.selectedVesselId = this.formData.vesselId;
      this.getAdInfoFormData(this.formData, this.formData.id);
      this.getFormData(this.formData, this.formData.id);
    } else {
      let elemData = this.formData;
      this.isAddNewQuestion = elemData.isAddNewQuestion;
      this.selectedVesselId = elemData.vesselId;
      const vesselId = elemData.vesselId;
      delete elemData['isAddNewQuestion'];
      delete elemData['vesselId'];
      this.getAdInfoFormData(this.formData, this.formData.id);
      this.getFormData(elemData, vesselId);
    }
  }

  closeForm() {
    this.isDataBind = false;
    this.formData = null;
    this.dataForEdit = null;
  }

  getAdInfoFormData(forms: any, vesselId: any) {
    if (forms.isAddNewQuestion) {
      this.formreportsService.getFormAdditionalInfoByFormId(vesselId, (res) => {
        console.log('resAdd_AddInfo: ', res);
      })
    } else {
      this.formreportsService.getFormAdditionalInfoByFormId(vesselId, (res) => {
        console.log('resEditAddInfo: ', res);
      })
    }
  }

  getFormData(forms: any, vesselId: any) {
    if (forms.isAddNewQuestion) {
      this.formreportsService.getFormsQuestionDetailsByFormsId(vesselId, (response) => {
        if (response.responsecode === 200 && response.data.length > 0) {
          this.dataForEdit = response.data;
          console.log('this.dataForEdit: ', this.dataForEdit);
          if (this.dataForEdit && this.dataForEdit.length > 0) {
            this.voyageNo = this.dataForEdit[0].voyageNo;
            //this.userId =  this.dataForEdit[0].userId;
            var oldSectionId = '';
            this.dataForEdit = this.dataForEdit.map((item, index) => {
              if (item.sectionId != oldSectionId) {
                oldSectionId = item.sectionId;
              } else {
                item.sectionName = '';
              }
              // console.log('Item: ', item);
              let additionInfo = {
                actionDate: this.isAddNewQuestion ? this.currentActionDate : item.actionDate,
                actionTime: this.isAddNewQuestion ? this.currentActionTime : item.actionTime,
                formId: item.formId,
                formInfoId: item.formId,
                infoDetails: null,
                infoLabel: null,
                vesselId: this.selectedVesselId
              }
              this.formansweradditioninfomodel.push(additionInfo);
              //if(this.isAddNewQuestion){
              item.objectId = null;
              item.questionId = item.questionId ? item.questionId : item.id;
              item.formId = vesselId;
              item.id = null;
              //}

              if (item.controlValue && item.controlType !== 'Checkbox') {
                item.controlValueArray = this.mapToKeyValueIndexArray(item.controlValue);
              }

              if (item.controlValue && item.controlType === 'Checkbox') {
                if (this.isAddNewQuestion) {
                  item.answer = null;
                }
                const array1 = this.mapToKeyValueIndexArray(item.controlValue);
                const array2 = item.answer ? this.mapToKeyValueIndexArray(item.answer) : [];
                // console.log('array1:', array1);
                // console.log('array1:', array2);
                // const map = new Map(array1.map(item => [item.value, item]));

                // Iterate through array2
                array2.forEach(item2 => {
                  array1.forEach(item1 => {
                    if (item2.value.trim() == item1.value.trim()) {
                      item1.isChecked = true;
                    }
                  });
                });

                item.controlValueArray = array1;
              }

              if (item.imagesData) {
                const values = item.imagesData.split(',');
                const result = [];
                if (this.isAddNewQuestion) {
                  item.imagesDataArray = [];
                  this.addNewImage(item.imagesDataArray, 0);
                } else {
                  item.imagesDataArray = this.mapToKeyValueIndexArray(item.imagesData);
                }
              }

              if (item.answer) {
                switch (item.controlType) {
                  case 'TimePicker':
                    // item.answer = this.modifyTime(item.answer);
                    item.answer = item.answer;
                    break;
                  case 'DatePicker':
                    // item.answer = this.modifyDate(item.answer);
                    item.answer = item.answer;
                    break;
                  // Add more cases as needed
                }
              }

              if (item) {
                switch (item.controlType) {
                  case 'TextBox':
                    let textBoxGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textBoxGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Text':
                    let textGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'TextArea':
                    let textareaGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textareaGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Radio':
                    let radioGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(radioGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.controlValueArray.forEach((radioItem, radioIndex) => {
                    //   let newControl = 'control_'+(index+1)+(radioIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'DropDown':
                    let dropdownGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(dropdownGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'DatePicker':
                    let datePicGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(datePicGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Checkbox':
                    let checkboxGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(checkboxGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.controlValueArray.forEach((checkboxItem, checkboxIndex) => {
                    //   let newControl = 'control_'+(index+1)+(checkboxIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'TimePicker':
                    let timePicGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(timePicGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'ImagePicker':
                    let imgGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [this.isAddNewQuestion ? this.currentActionDate : item.actionDate],
                      actionTime: [this.isAddNewQuestion ? this.currentActionTime : item.actionTime],
                      answer: [!this.isAddNewQuestion ? item.answer : null],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(imgGroup);
                    if (this.isAddNewQuestion) { item.answer = null; }
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.imagesDataArray.forEach((imageItem, imageIndex) => {
                    //   let newControl = 'control_'+(index+1)+(imageIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                }
              }
              this.isDataBind = true;
              return item;
            });
          }
        } else {
          this.noData.show();
          this.isDataBind = false;
          this.dataForEdit = null;
        }
      });
    } else {
      this.formreportsService.getFormAnswerDetailsByFormIdAndDateWithTimev1(forms, vesselId, (response) => {
        if (response.responsecode === 200 && response.data.length > 0) {
          this.dataForEdit = response.data;
          console.log('dataForEdit: ', this.dataForEdit);
          // console.log('ResData_22: ', this.isAddNewQuestion);
          if (this.dataForEdit && this.dataForEdit.length > 0) {
            this.voyageNo = this.dataForEdit[0].voyageNo;
            //this.userId =  this.dataForEdit[0].userId;
            var oldSectionId = '';
            this.dataForEdit = this.dataForEdit.map((item, index) => {
              if (item.sectionId != oldSectionId) {
                oldSectionId = item.sectionId;
              } else {
                item.sectionName = '';
              }

              let additionInfo = {
                actionDate: item.actionDate,
                actionTime: item.actionTime,
                formId: item.formId,
                formInfoId: item.formId,
                infoDetails: null,
                infoLabel: null,
                vesselId: item.vesselId,
              }
              this.formansweradditioninfomodel.push(additionInfo);
              //if(this.isAddNewQuestion){
              item.objectId = null;
              item.id = null;
              //item.questionId = null;
              //}

              if (item.controlValue && item.controlType !== 'Checkbox') {
                item.controlValueArray = this.mapToKeyValueIndexArray(item.controlValue);
              }

              if (item.controlValue && item.controlType === 'Checkbox') {
                const array1 = this.mapToKeyValueIndexArray(item.controlValue);
                const array2 = item.answer ? this.mapToKeyValueIndexArray(item.answer) : [];
                // console.log('array1:', array1);
                // console.log('array1:', array2);
                // const map = new Map(array1.map(item => [item.value, item]));

                // Iterate through array2
                array2.forEach(item2 => {
                  array1.forEach(item1 => {
                    if (item2.value.trim() == item1.value.trim()) {
                      item1.isChecked = true;
                    }
                  });
                });

                item.controlValueArray = array1;
              }

              if (item.imagesData) {
                const values = item.imagesData.split(',');
                const result = [];
                item.imagesDataArray = this.mapToKeyValueIndexArray(item.imagesData);
              }

              if (item.answer) {
                switch (item.controlType) {
                  case 'TimePicker':
                    // item.answer = this.modifyTime(item.answer);
                    item.answer = item.answer;
                    break;
                  case 'DatePicker':
                    // item.answer = this.modifyDate(item.answer);
                    if (item.answer) {
                      var months = {
                        "Jan": 0, "Feb": 1, "Mar": 2, "Apr": 3, "May": 4, "Jun": 5,
                        "Jul": 6, "Aug": 7, "Sep": 8, "Oct": 9, "Nov": 10, "Dec": 11
                      };

                      var parts = item.answer.split('-');
                      var day = parseInt(parts[0]);
                      var month = months[parts[1]];
                      var year = parseInt(parts[2]);

                      var formattedDate = new Date(Date.UTC(year, month, day));

                      item.answer = formattedDate;
                    }
                    break;
                  // Add more cases as needed
                }
              }

              if (item) {
                switch (item.controlType) {
                  case 'TextBox':
                    let textBoxGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textBoxGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Text':
                    let textGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'TextArea':
                    let textareaGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(textareaGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Radio':
                    let radioGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(radioGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.controlValueArray.forEach((radioItem, radioIndex) => {
                    //   let newControl = 'control_'+(index+1)+(radioIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'DropDown':
                    let dropdownGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(dropdownGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'DatePicker':
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                    const date = new Date(item.answer);
                    const day = date.getDate();
                    const monthIndex = date.getMonth();
                    const year = date.getFullYear();
                    const newDate = `${day}-${months[monthIndex]}-${year}`;
                    let datePicGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [newDate],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(datePicGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'Checkbox':
                    let checkboxGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(checkboxGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.controlValueArray.forEach((checkboxItem, checkboxIndex) => {
                    //   let newControl = 'control_'+(index+1)+(checkboxIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'TimePicker':
                    let timePicGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(timePicGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                  case 'ImagePicker':
                    let imgGroup = this.formBuilder.group({
                      // actionDate: ['', Validators.required],
                      actionDate: [item.actionDate],
                      actionTime: [item.actionTime],
                      answer: [item.answer],
                      formId: [item.formId],
                      imagesData: [item.imagesData],
                      imagesDataLocal: [null],
                      questionId: [item.questionId],
                      sectionId: [item.sectionId],
                      rowId: [item.rowId],
                      controlType: [item.controlType],
                      changeQuestionIds: [null]
                    });
                    this.itemsFormArray.push(imgGroup);
                    // this.dynamicForm.addControl(`control_${(index+1)}`, new FormControl(''));
                    // item.imagesDataArray.forEach((imageItem, imageIndex) => {
                    //   let newControl = 'control_'+(index+1)+(imageIndex+1);
                    //   this.dynamicForm.addControl(newControl, new FormControl(''));
                    // })
                    // setTimeout(() => {
                    //   this.setControlValue(index, item.answer);
                    // }, 500);
                    break;
                }
              }
              this.isDataBind = true;
              return item;
            });
            console.log('dataForEdit1: ', this.dataForEdit);
          }
        } else {
          this.noData.show();
          this.isDataBind = false;
          this.dataForEdit = null;
        }
      });
    }
  }

  get itemsFormArray() {
    return this.form.get('items') as FormArray;
  }

  removeItem(index: number) {
    this.itemsFormArray.removeAt(index);
  }

  addNewImage(itemData: any, imgIndex: number) {
    let neItem = {
      "id": imgIndex + 1,
      "name": "",
      "value": ""
    }
    if (itemData) {
      itemData.push(neItem);
    } else {
      let newImgData = [];
      newImgData.push(neItem);
      itemData = newImgData;
    }
  }

  addNewImageFresh(question: any, imgIndex: number) {
    let neItem = {
      "id": imgIndex + 1,
      "name": "",
      "value": ""
    }
    console.log("FreshImage::", neItem);
    this.dataForEdit.map((qu, i) => {
      if (qu && i === imgIndex) {
        qu.imagesDataArray = [],
          qu.imagesDataArray.push(neItem);
        return qu;
      }
      return qu;
    })
    console.log("FreshImage-dataForEdit::", this.dataForEdit);
  }

  removeImage(itemData: any, id: number, pIdx, cidx) {
    if (itemData && itemData.length > 0) {
      const index = itemData.findIndex(item => item.id === id);
      if (index !== -1) {
        itemData.splice(index, 1);
      };
      let cloneList2 = itemData && JSON.parse(JSON.stringify(itemData));
      cloneList2 = cloneList2.map((item) => { return item.value.trim() });
      this.form.controls.items.value[pIdx].imagesDataLocal = cloneList2.join(",");
      this.form.controls.items.value[pIdx].imagesData = cloneList2.join(",");
      // console.log('OGL', itemData, cloneList2);
    }
  }

  mapToKeyValueIndexArray(csv) {
    // Split the CSV string into an array of values
    const values = csv.split(',');
    // Create an empty object to store the result
    const result = [];
    // Loop through the values array
    values.forEach((value, index) => {
      let newVal = {
        id: (index + 1),
        name: value,
        value: value
      }
      result.push(newVal);
    });
    return result;
  }

  modifyTime(time) {
    let timeParts = time && time.split(":");
    let hours = timeParts[0];
    let minutes = timeParts[1];
    let ampm = timeParts[2] && timeParts[2].split(" ")[1]; // Extracting AM/PM part

    time = hours + ":" + minutes + " " + ampm;
    return time;
  }

  modifyDate(inputDate) {
    if (inputDate) {
      var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      // Split the input date into day, month, and year
      var parts = inputDate.split('-');
      var day = parseInt(parts[0]);
      var month = parts[1];
      var year = parseInt(parts[2]);

      // Create a new Date object with the given day, month, and year
      var date = new Date(year, monthNames.indexOf(month), day);

      // Output the date in the desired format
      inputDate = date.toUTCString();
    }
  }

  uploadProfileImage(event, imagesDataArray, control, pIdx, cIdx) {
    console.log("event:::", event);
    console.log("control", control)
    console.log("pIdx and cIdx", pIdx, cIdx);
    console.log("DataEditForm:::", this.dataForEdit);
    console.log("imagesDataArray:::", imagesDataArray);
    let index = imagesDataArray.length - 1;
    console.log("index::", index);
    let value = control.value;
    // const fileInfo = document.querySelector(".file-info");
    const file_Info = document.getElementById('file_info_' + pIdx + cIdx + control.id);
    const name = event.target.value.split(/\\|\//).pop();
    // const truncated = name.length > 20 ? name.substr(name.length - 20) : name;

    // file_Info.innerHTML = "<span style='padding-left: 5px; line-height: 37px;'>" + truncated + "</span>";
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files.length > 0) {
        const formData = new FormData();
        let fileName = event.target.files[i].name.replace(/\s/g, '');
        let filetype = event.target.files[i].type;
        if (filetype == 'image/png' || filetype == 'image/jpeg' || filetype == 'image/gif' || filetype == 'image/jpg') {
          formData.append("file", event.target.files[i], fileName);
          const file = event.target.files[0];
          // if(file.size > 1048576){//1mb //50kb
          //   // "Maximum upload size exceeded; nested exception is java.lang.IllegalStateException: org.apache.tomcat.util.http.fileupload.FileUploadBase$FileSizeLimitExceededException: The field file exceeds its maximum permitted size of 1048576[1MB] bytes.
          //   this.errorbox.text= 'Maximum upload size exceeded its maximum permitted size of 1048576 bytes [1MB].';
          //   this.errorbox.show();
          //   return;
          // }
          this.fileFormData = formData;

          this.crewService.uploadImage(formData, (response) => {
            // console.log(response);
            if (response.responsecode == 200) {
              console.log("Enter:::")
              // this.crewService.formData.profileImage = response.fileurl;
              file_Info.innerHTML = "<span style='padding-left: 5px; line-height: 37px;'>" + response.filename + "</span>";
              // control.value = '/campbellservice/downloadFile/'+response.filename;
              let resValue = '/campbellservice/downloadFile/' + response.filename;
              let newItems = {
                "id": this.count,
                "name": "",
                "value": resValue
              }

              this.dataForEdit.map((qu, i) => {
                // [...qu.imagesDataArray]
                //console.log("i::",i);
                if (qu && i === pIdx) {
                  console.log("qu.imageArray", qu.imagesDataArray[0].value)
                  let newValue = qu.imagesDataArray[0].value;
                  //  console.log("newValue::",newValue);
                  //  console.log("newItems::",newItems);
                  //  console.log("control Value::",value,control.value=='');
                  //  if(newValue===newItems.value){

                  if (value == '') {
                    console.log("i::", i);
                    qu.imagesDataArray[0] = newItems;
                    console.log("newValueIF::", newItems);
                    value = newItems.value;
                    return qu
                  } else {
                    console.log("i::", i);
                    index = index + 1;
                    qu.imagesDataArray[index] = newItems;
                    console.log("Index+1::", index);
                    console.log("newValueElse::", newItems);
                    return qu;
                  }

                }
                return qu;

              })
              //control.value=resValue;
              let cloneList = imagesDataArray && JSON.parse(JSON.stringify(imagesDataArray));
              cloneList = cloneList.map((item) => { return response.filename });
              let cloneList2 = imagesDataArray && JSON.parse(JSON.stringify(imagesDataArray));
              cloneList2 = cloneList2.map((item) => { return item.value.trim() });
              this.form.controls.items.value[pIdx].imagesDataLocal = cloneList2.join(",");
              this.form.controls.items.value[pIdx].imagesData = cloneList2.join(",");
              this.count++;
              console.log("count::", this.count);
              // this.imageItem.push(newItems);


            } else {
              let ele = document.getElementById('image_' + pIdx + cIdx + control.id) as HTMLInputElement;
              ele.value = '';
              file_Info.innerHTML = "<span style='padding-left: 5px; line-height: 37px;'>Choose file..</span>";
              control.value = null;
              control = control;
              imagesDataArray[cIdx] = control;
              this.errorbox.text = response.message;
              this.errorbox.show();
            }
          });
          // console.log(this.service.uploadDocumentsDriver);
        } else {
          this.errorbox.text = 'Supported image formats: JPEG, PNG, and GIF only.';
          this.errorbox.show();
        }

      }
    }
    console.log("ImageItem::", this.imageItem);
    console.log("dataForEdit::", this.dataForEdit);
  }



  onChange(question: any, index: number, value: any) {
    // console.log(`Value changed at index ${index}: ${value}`, this.form.controls.items.value[index].answer);
    if (value) {
      this.form.controls.items.value[index].answer = value;
    }
    // You can update the value in the form array here if needed
  }

  onChangeRadio(question: any, index: number, event: any) {
    // console.log("Value changed", this.form.controls.items.value[index].answer, event.value);
    if (event.value) {
      this.form.controls.items.value[index].answer = event.value;
    }
    // You can update the value in the form array here if needed
  }

  onEndTimePickerClosedForAdd(question: any, index, event) {
    let timeElement = document.getElementById('timePicker_' + question.formsId + index) as HTMLInputElement;
    if (timeElement.value) {
      this.form.controls.items.value[index].answer = timeElement.value;
    }
  }

  onEndTimePickerClosedForEdit(question: any, index, event) {
    let timeElement = document.getElementById('timePicker_' + question.id + index) as HTMLInputElement;
    if (timeElement.value) {
      this.form.controls.items.value[index].answer = timeElement.value;
    }
  }

  onDateChange(question, index, event: any) {
    if (event.target.value) {
      question.answer = event.target.value;
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const date = new Date(event.target.value);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();
      const newDate = `${day}-${months[monthIndex]}-${year}`;
      this.form.controls.items.value[index].answer = newDate;
    }
    // this.getData(newDate);
  }

  onChangeDropdown(question: any, index: number, value: any) {
    // console.log("Value changed DD", this.form.controls.items.value[index].answer, value);
    if (value) {
      this.form.controls.items.value[index].answer = value;
    }
    // You can update the value in the form array here if needed
  }

  onCheckboxChange(event: any, checkBoxArr: any, question: any, item: any, pIndex: any, cIndex: any) {
    if (event.checked && item.value) {
      item.isChecked = true;
      const checkedValues = checkBoxArr.length > 0 && checkBoxArr
        .filter(item => item.isChecked)
        .map(item => item.value);
      const result = checkedValues;
      this.form.controls.items.value[pIndex].answer = result.join(", ");
    } else {
      item.isChecked = false;
      const checkedValues = checkBoxArr.length > 0 && checkBoxArr
        .filter(item => item.isChecked)
        .map(item => item.value);
      const result = checkedValues;
      this.form.controls.items.value[pIndex].answer = result.join(", ");
    }
  }



  onSubmit() {


    this.loading = true;
    let questionIdList = this.form.controls.items.value.length > 0 && this.form.controls.items.value.map(item => item.questionId);
    let newReqBody = [
      {
        changeFields: this.isAddNewQuestion ? "" : questionIdList,
        formansweradditioninfomodel: this.formansweradditioninfomodel,
        imagesData: "[]",
        isMobileSync: false,
        questionWithAnswer: this.form.controls.items.value,
        objectId: "",
        responsecode: 0,
        userId: this.userId,
        userSign: "",
        vesselId: this.selectedVesselId,
        voyageNo: this.voyageNo
      }
    ]

    let formValues = [];
    // console.log('Form_1:', this.form);
    // console.log('Form_2_data: ', this.dataForEdit);
    // console.log('Form_3:', this.form.controls.items.value);
    formValues = this.form.controls.items.value;
    const formData = new FormData();
    formData.append('model', JSON.stringify(newReqBody));  //convert reqbody to model formData
    // return;
    console.log("====formData===", formData);
    if (this.isAddNewQuestion) {

      this.formreportsService.saveNewFormAnswerDetailsList(formData, (resData) => {
        if (resData.responsecode === 200 || resData.responsecode === '200') {
          // this.successbox.text=result['message'];
          this.successbox.show();
          this.updateParent.emit(resData);
          this.loading = false
        }
      });

    } else {

      this.formreportsService.saveFormAnswerDetailsList(formData, (resData) => {
        if (resData.responsecode === 200 || resData.responsecode === '200') {
          // this.successbox.text=result['message'];
          this.successbox.show();
          this.updateParent.emit(resData);
          this.loading = false;
        }
      });

    }

  }

  saveFormDetail() {

    this.approval.title = 'Mark as completed';
    this.approval.text = 'Are you sure you want to mark this form as completed? Once you mark it as completed, you will not be able to make any changes.';
    this.approval.show();
  }

  completationSignOff(){
        console.log("saveformdetail is clicked")
    let questionIdList = this.form.controls.items.value.length > 0 && this.form.controls.items.value.map(item => item.questionId);
    let newReqBody = [
      {
        changeFields: this.isAddNewQuestion ? "" : questionIdList,
        formansweradditioninfomodel: this.formansweradditioninfomodel,
        imagesData: "[]",
        isMobileSync: false,
        questionWithAnswer: this.form.controls.items.value,
        objectId: "",
        responsecode: 0,
        userId: this.userId,
        userSign: "",
        vesselId: this.selectedVesselId,
        voyageNo: this.voyageNo
      }
    ];
    const formData = new FormData();
    formData.append('model', JSON.stringify(newReqBody));
    console.log('check-form-value', this.form.controls.items.value )
    
    this.formreportsService.completeSaveFormAnswer(formData, (res) => {
      if (res.responsecode === 200 || res.responsecode === '200') {
        console.log('check-res-here', res)
        console.log('check-isComplete-here', res.data.isComplete)
        this.successbox.show();
        this.updateParent.emit(res); 
      }
    })
  }


}
