import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SailingModesService } from '../sailing-modes.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-sailing-modes',
  templateUrl: './sailing-modes.component.html',
  styleUrls: ['./sailing-modes.component.css']
})
export class SailingModesComponent implements OnInit {

  //displayedColumns: string[] = ['id', 'sailingMode', 'watchHourTiming', 'description', 'edit'];
  displayedColumns: string[] = ['sailingMode', 'watchHourTiming', 'description', 'edit'];
  dataSource

  myForm: FormGroup;
  generatewatchhour: FormGroup;
  watchhourbydate :FormGroup
  afs: any;
  success: boolean;
  loading: boolean;
  data: any = [];
  someArray=[];
  fieldTypeId: any;
  product: any;
  error = 0;
  message="Record added successfully";
  public formData;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('deleteSwal', { static: false }) public deleteSwal: SwalComponent;
  @ViewChild('successbox', { static: true }) public successbox: SwalComponent;

  @ViewChild('errorbox', { static: true }) public errorbox: SwalComponent;

  constructor(public fb: FormBuilder, public sailingModesService: SailingModesService,
    public http: HttpClient) {
    this.resetForm();

  }

  ngOnInit() {

    // this.getProductDetails(this.route.snapshot.params['id']);

    this.sailingModesService.getData((response) => {
      console.log(response);
      this.dataSource = new MatTableDataSource(response.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

    this.myForm = this.fb.group({
      'sailingMode': new FormControl(null, [Validators.required]),
      'watchHourTiming': new FormControl(null, [Validators.required]),
      'description': new FormControl(null, [Validators.required])

    })


  }

  submitHandler() {

    if (this.myForm.valid) {
      this.loading = true;
      console.log(this.myForm.value);
      // console.log(this.myForm.value.watchHourTiming.split(','));
      // console.log(this.sailingModesService.formData);
      if (this.sailingModesService.formData.id) {
        this.sailingModesService.formData.watchHourTiming = this.myForm.value.watchHourTiming;
        this.sailingModesService.putData(this.sailingModesService.formData.id, this.sailingModesService.formData, (data) => {
          console.log(data);
          if (data.responsecode === 200 || data.responsecode === '200') {
            this.sailingModesService.getData((response) => {
              console.log(response);
              this.dataSource = new MatTableDataSource(response.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.loading = false;
              this.successbox.show();
              this.resetForm();
              this.myForm.reset();
            })
          } else {
            this.loading = false
            this.errorbox.text = data.message;
            this.errorbox.show();
          }
        })
      } else {
        this.sailingModesService.formData.watchHourTiming = this.myForm.value.watchHourTiming.split(',');
        this.sailingModesService.postData(this.sailingModesService.formData,(response) => {
        console.log(response)

          if (response[0].responsecode === 200 || response[0].responsecode === '200') {
          this.sailingModesService.getData((response) => {
            console.log(response);

            this.dataSource = new MatTableDataSource(response.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.loading = false;

            // this.someArray.forEach((response, index) => {
            //   if (response.responsecode === 200 || response.responsecode === '200') {
            //     this.successbox.text = this.message;
            //   }else{
            //     this.successbox.text = this.message;
            //     this.error+1;
            //   }

            // });
            // if(this.error>0){
            //   this.errorbox.text = response.message;
            //   this.errorbox.show();
            // }

            this.successbox.show();
            this.resetForm();
            this.myForm.reset();
          })
          //  console.log(response);
        }else{
          this.loading = false;
          this.errorbox.text = response.message;
          this.errorbox.show();
        }
        })
        // }

      }
    }


  }

  // getProductDetails(id) {
  //   this.fts.getDataById(id,(response)=>{
  //     console.log(response);
  //     this.product = response;
  //   })
  // }

  onClick(action, data) {
    console.log(data);
    if (action == 'edit') {
      this.sailingModesService.formData.id = data.id;
      this.sailingModesService.formData.description = data.description;
      this.sailingModesService.formData.sailingMode = data.sailingMode;
      this.myForm.controls['watchHourTiming'].setValue(data.watchHourTiming);
      // this.sailingModesService.formData.watchHourTiming = data.watchHourTiming;
    } else {
      this.sailingModesService.deleteData(data.id).subscribe((data) => {
        console.log('Deleted');
        this.sailingModesService.getData((response) => {
          console.log(response);
          this.dataSource = new MatTableDataSource(response.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        })
      })
    }
    window.scroll(0,0);

  }

  resetForm() {
    this.sailingModesService.formData.id = "";
      this.sailingModesService.formData.description = "";
      this.sailingModesService.formData.sailingMode = "";
    // console.log(this.myForm);
    if(this.myForm){

      this.myForm.controls['watchHourTiming'].setValue(null);
    }

    // this.myForm.reset();
  }

}
